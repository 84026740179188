import Config from '../Config';
import NucleusNetwork from '../utils/NucleusNetwork';
class CaregiverApi {
  private context: any;
  constructor(context) {
    this.context = context;
  }

  //<----------------------------------Get All Caregivers Data--------------------------->
  getAllCaregiverData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/List',
      data: data,
      callback: callback,
    });
  }

  // //<----------------------------------Get User Data-------------------------------->
  // GetCarePatientData (data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "GET",
  // 		url: Config.domain + "GetCarePatientData.aspx",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  //<---------------------------------Update Caregiver Data----------------------------->
  UpdateCaregiverData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/Update',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Create New Caregiver--------------------------->
  CreateNewCaregiver(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/Add',
      data: data,
      callback: callback,
    });
  }

  //<---------------------------------Delete Patient--------------------------------->
  Delete(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/Delete',
      data: data,
      callback: callback,
    });
  }

  // //<-----------------------Get Available Devices for Patient----------------------->
  // GetCareDevices (data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "GET",
  // 		url: Config.domain + "GetCareDevices.aspx",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  // //<-----------------------Assign Device To Patient ----------------------->
  // AssignDevicePatient (data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "GET",
  // 		url: Config.domain + "AssignPatientDevice.aspx",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  // //<-----------------------Unassign Device To Patient ----------------------->
  // UnassignDevicePatient (data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "GET",
  // 		url: Config.domain + "UnassignPatientDevice.aspx",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  //<-------------------------------Add New Assignment ----------------------->
  AddNewAssignment(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/AddAssignment',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------------Add New Assignment ----------------------->
  UpdateAssignment(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/UpdateAssignment',
      data: data,
      callback: callback,
    });
  }

  //<-----------------------------------Check In ----------------------------->
  CheckIn(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/CheckIn',
      data: data,
      callback: callback,
    });
  }

  //<-----------------------------------Check Out----------------------------->
  CheckOut(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/CheckOut',
      data: data,
      callback: callback,
    });
  }

  //<------------------------------Delete Assignment------------------------->
  DeleteAssignment(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/DeleteAssignment',
      data: data,
      callback: callback,
    });
  }

  //<------------------------Get Caregiver Assignments ------------------------>
  GetCaregiverAssignments(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/ListAssignments',
      data: data,
      callback: callback,
    });
  }

  // //<-----------------------Update Call Log Notes --------------------------->
  // UpdateCallLogNotes (data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "UpdateCareCallLogNotes.aspx",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  SendCaregiverSMS(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/SendSMS',
      data: data,
      callback: callback,
    });
  }
}

export default CaregiverApi;
