import { onCallEvent, onCallPeerVideoSize } from '@nucleus-care/nucleuscare-backend-client';
import { FullCallEntity, WebRTCCall } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { RootState } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/store';
import {
  NucleusSignaling,
  NucleusCommunicationSignaling,
  SignalingEvent,
  CommunicationSignalingEvent,
  MultiCallType,
  SignalingPeerMessageUserData,
  CallEntity,
  SignalingId,
  CallMessage,
} from '@nucleus-care/nucleuscare-connect-signaling';
import { NucleusCallView, Region, NucleusWebRTC } from '@nucleus-care/nucleuscare-connect-webrtc';
import CallPlayer from 'components/CallPlayer';
import { MuteSVG, EndCallSVG } from 'components/CallSVGs/CallsSVGs';

import CustomSlider from 'components/CustomSlider';
import i18n from 'i18n';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CallButton from './CallButton';

export enum CallingMethods {
  Call = 'Call',
  Notify = 'Notify',
}

enum CallDataTypes {
  CallDeclined = 'call_declined',
  CallBusy = 'call_busy',
}

type CallData = {
  type: CallDataTypes;
  callLogRequestId: string;
};

let peerSizesMap = {};

export const WebRTCCallView = ({
  RingingScreen,
  call,
  onCallRejected,
  onCallDisconnected,
  onCallEnded,
  onCallCanceled,
  onCallTimeout,
  onCallBusy,
  onCallDoNotDisturb,
  onCallFailed,
  onCallEstablished,
  onCallInitiated,
  onCallStarted,
  onCallHandled,
  onNotifyCall,
  callingMethod,
}: {
  RingingScreen?: () => JSX.Element;
  call?: WebRTCCall;
  onCallRejected?: () => void;
  onCallDisconnected?: () => void;
  onCallEnded?: () => void;
  onCallCanceled?: (dontClose?: boolean) => void | Promise<void>;
  onCallTimeout?: () => void;
  onCallBusy?: () => void;
  onCallDoNotDisturb?: (data: any) => void;
  onCallFailed?: () => void;
  onCallEstablished?: () => void;
  onCallInitiated?: () => void;
  onCallStarted?: () => Promise<void>;
  onCallHandled?: () => void;
  onNotifyCall?: () => void;
  callingMethod?: CallingMethods;
}) => {
  const callState = useSelector((state: RootState) => {
    //console.log("WebRTCCallView useSelector 1", state);
    return state.call.callParticipantsStatus ? state.call.callParticipantsStatus : null;
  });

  const [InCall, setInCall] = useState(false);

  const signaling = useRef(NucleusSignaling.getInstance());
  const communication = useRef(NucleusCommunicationSignaling.getInstance());
  const webrtc = useRef(NucleusWebRTC.getInstance());
  const dispatch = useDispatch();

  const volBar = useRef<HTMLInputElement>();
  const [stateInitialMute, setStateInitialMute] = useState(false);
  const [stateParticipantNames, setStateParticipantNames] = useState('');
  const [connectedParticipants, setConnectedParticipants] = useState<FullCallEntity[]>([]);

  const useWebrtc = webrtc.current;
  const useSignaling = signaling.current;

  useEffect(() => {
    // Backwards compatibility for overnight app
    // We need to remove this when the overnight app is updated
    const singleParticipant = call.participants.length === 1;
    const singleDevice = call.participants[0].devicesIds.length === 1;
    if (singleDevice && singleParticipant && call.callingMethod === CallingMethods.Notify) {
      //@ts-ignore
      const fullCallEntity: FullCallEntity[] = call.participants.map(participant => ({
        ...participant,
        name: call.patientName,
      }));
      setConnectedParticipants(fullCallEntity);
    }
  }, [call.participants]);

  const endCall = () => {
    if (communication.current.isCallEstablished()) {
      communication.current.endCurrentCall();
    }
    onCallEnded();
  };

  const onCall = (call: CallMessage) => {
    console.log('Got Call: ' + JSON.stringify(call));

    if (call.state === 'Initiated') {
      // callConnected()
      if (!communication.current.isCallEstablished() && !communication.current.isCallEnded()) {
        communication.current.acceptCurrentCall();
      }
    }
    if (communication.current.isCallEnded()) {
      //setGotCall(false);
      setInCall(false);
    }
  };

  const startCall = async () => {
    console.log('starting call **************', call);
    //InitialMute = false
    setStateInitialMute(false);
    await onCallStarted();

    peerSizesMap = {};

    if (call.multiCall) {
      setStateParticipantNames('');
      let names = '';
      if (call.participants && call.participants.length) {
        switch (call.participants.length) {
          case 1:
            names = call.participants[0].entityId;
            break;
          case 2:
            names = call.participants[0].entityId + ', ' + call.participants[1].entityId.slice(0, 9) + '...';
            break;
          default:
            names = call.participants[0].entityId + ', ' + call.participants[1].entityId.slice(0, 9) + '...      ' + ' + ' + (call.participants.length - 2) + ' more';
            break;
        }
      }
      //console.log("starting call ************** names", names)
      setStateParticipantNames(names);
    }

    switch (call.type) {
      case 'Video':
      case 'Audio':
      case 'Barge':
        setStateInitialMute(false);
        break;

      case 'SilentBarge':
        if (call.multiCall) {
          setStateInitialMute(false);
        } else {
          setStateInitialMute(true);
        }
        break;
    }
    let multiCallType = MultiCallType.Conference;
    if (call.multiCall) {
      multiCallType = MultiCallType.Class;
    }
    const callTimeout = call.timeoutInSeconds || 62;
    const initials = call.patientFirstName.charAt(0) + call.patientLastName.charAt(0);
    const participants: CallEntity[] = call.participants.map(participant => ({
      entityId: participant.entityId,
      devicesIds: participant.devicesIds,
    }));
    const callWithParams = {
      toParticipants: participants,
      callLogId: call.callLogId,
      callType: call.type,
      multiCallType,
      initiallyMutedParticipants: [],
      timeoutSeconds: callTimeout,
      peerInitials: initials,
      peerName: call.patientName,
      peerThumb: '',
    };
    console.log('callWithParams', callWithParams);
    communication.current.callWithParams(callWithParams);
  };

  const handleVolBar = () => {
    iterateConnectedDevices(deviceId => {
      signaling.current.sendUserDataMessageToPeer(
        deviceId as unknown as SignalingId, //Cast to signalingId
        JSON.stringify({
          //@ts-ignore
          VOLUME: (volBar.current.value ?? 1) / 100,
        }),
      );
    });
  };

  const onCallData = (callData: CallMessage) => {
    console.log('onCallData - from signaling', callData);
    switch (callData.state) {
      case 'Initiated':
        setInitialVolumeValues();
        onCallInitiated();
        break;
      case 'Established':
        setInCall(true);
        onCallEstablished();

        onCallHandled();
        break;
      case 'TimedOut':
        onCallTimeout();
        break;
      case 'Cancelled':
        onCallCanceled();
        onCallHandled();
        break;
      case 'Ended':
        endCall();
        onCallHandled();
        break;
      case 'PeerQuitted':
        if (!call.multiCall) {
          endCall();
          onCallEnded();
          onCallHandled();
        }
        break;
      case 'Failed':
        onCallFailed();
        onCallHandled();
        break;
      case 'Rejected':
        if (!call.multiCall) {
          console.log('case Rejected');
          onCallRejected();
          //onCallHandled()
        }
        break;
      case 'Busy':
        if (!call.multiCall) {
          onCallBusy();
          onCallHandled();
        }
        break;
      case 'DND':
        if (!call.multiCall) {
          onCallDoNotDisturb(callData);
          onCallHandled();
        }
        break;
      default:
        break;
    }

    if (callData.state == 'Established' && call.multiCall) {
      updateParticipantStatus(callData.senderPeerId, 'connected');
      //NucleusCallView.unmuteAudio();
      //setStateInitialMute(false);
      //Message.show("Unmuted!")
    }
  };

  const onPeerDisconnected = disconnectedPeerId => {
    console.log('T onPeerDisconnected', disconnectedPeerId);
    updateParticipantStatus(disconnectedPeerId, 'disconnected');
  };

  const onPeerRejected = rejectedPeerId => {
    console.log('T onPeerRejected', rejectedPeerId);
    console.log('call', call);
    if (!call.multiCall) {
      onCallRejected();
    }
    updateParticipantStatus(rejectedPeerId, 'rejected');
  };

  const onPeerTimedOut = timedOutPeerId => {
    console.log('T onPeerTimedOut', timedOutPeerId);
    updateParticipantStatus(timedOutPeerId, 'timedOut');
    //TODO: check if all participants are timed out in a better way
    // this was implemented for Classes
    if (!call.multiCall && !communication.current.isCallEstablished()) {
      onCallTimeout();
    }
  };
  const onPeerFailed = failedPeerId => {
    console.log('T onPeerFailed', failedPeerId);
    updateParticipantStatus(failedPeerId, 'failed');
  };
  const onPeerBusy = busyPeerId => {
    console.log('T onPeerBusy', busyPeerId);
    updateParticipantStatus(busyPeerId, 'busy');
  };

  const onPeerOnDoNotDisturb = dndPeerId => {
    console.log('T onPeerOnDoNotDisturb', dndPeerId);
    updateParticipantStatus(dndPeerId, 'DND');
  };

  const updateParticipantStatus = (peerId, status) => {
    console.log('updateParticipantStatus', peerId, status);
    console.log('updateParticipantStatus Store', callState);
    dispatch(
      onCallEvent({
        peerId,
        status,
      }),
    );
  };

  const onPeerVideoSize = (participantId: string, width: number, height: number) => {
    console.log('onPeerVideoSize: ' + participantId + ', size: ' + width + 'x' + height);
    const ratio = width / height;
    const ratioFactor = ratio > 1.5 ? '16:9' : '4:3';
    if (peerSizesMap && peerSizesMap[participantId] && peerSizesMap[participantId].ratio != ratioFactor) {
      peerSizesMap[participantId] = { ratio: ratioFactor };
      dispatch(
        onCallPeerVideoSize({
          peerId: participantId,
          ratioFactor,
        }),
      );
    }
  };

  const onDataFromPeer = (data: SignalingPeerMessageUserData) => {
    const userData = JSON.parse(data.userData) as CallData;
    switch (userData.type) {
      case CallDataTypes.CallDeclined:
        console.log('call declined');
        onCallRejected();
        break;
      case CallDataTypes.CallBusy:
        console.log('call declined');
        onCallBusy();
        break;
    }
    console.log('onDataFromPeer ==>', data);
  };
  //
  const onNeedToRenderParticipant = (renderEntity: CallEntity) => {
    console.log(`onNeedToRenderParticipant - render entity: ${JSON.stringify(renderEntity)} \n Entities: ${JSON.stringify(connectedParticipants)}`);
    const particpantAlreadyRerendered = connectedParticipants.find(participant => participant.entityId === renderEntity.entityId);
    if (particpantAlreadyRerendered) {
      return;
    }
    const fullCallRenderEntity = {
      ...renderEntity,
      name: call.patientName,
    } as FullCallEntity;

    setConnectedParticipants([...connectedParticipants, fullCallRenderEntity]);
  };

  useEffect(() => {
    useSignaling.addListener(SignalingEvent.USER_DATA_FROM_PEER, onDataFromPeer);
    communication.current.addListener(CommunicationSignalingEvent.CallReceived, onCallData);
    communication.current.addListener(CommunicationSignalingEvent.CallDisconnected, onCallDisconnected);
    communication.current.addListener(CommunicationSignalingEvent.CallBusy, onCallBusy);
    communication.current.addListener(CommunicationSignalingEvent.CallFailed, onCallFailed);

    communication.current.addListener(CommunicationSignalingEvent.ParticipantDisconnected, onPeerDisconnected);
    communication.current.addListener(CommunicationSignalingEvent.ParticipantRejectedCall, onPeerRejected);
    communication.current.addListener(CommunicationSignalingEvent.ParticipantCallTimedOut, onPeerTimedOut);
    communication.current.addListener(CommunicationSignalingEvent.ParticipantCallFailed, onPeerFailed);
    communication.current.addListener(CommunicationSignalingEvent.ParticipantIsBusy, onPeerBusy);
    communication.current.addListener(CommunicationSignalingEvent.ParticipantIsOnDoNotDisturbState, onPeerOnDoNotDisturb);
    communication.current.addListener(CommunicationSignalingEvent.NeedToRenderParticipant, onNeedToRenderParticipant);
    communication.current.addListener(CommunicationSignalingEvent.GotVideoSize, onPeerVideoSize);

    useWebrtc.initialize(Region.US, false);

    switch (callingMethod) {
      case CallingMethods.Call:
        startCall();
        break;
      case CallingMethods.Notify:
        onNotifyCall();
        break;
      default:
        startCall();
        break;
    }

    return () => {
      useSignaling.removeListener(SignalingEvent.USER_DATA_FROM_PEER, onDataFromPeer);
      communication.current.removeListener(CommunicationSignalingEvent.CallReceived, onCallData);
      communication.current.removeListener(CommunicationSignalingEvent.CallDisconnected, onCallDisconnected);
      communication.current.removeListener(CommunicationSignalingEvent.CallBusy, onCallBusy);
      communication.current.removeListener(CommunicationSignalingEvent.CallFailed, onCallFailed);

      communication.current.removeListener(CommunicationSignalingEvent.ParticipantDisconnected, onPeerDisconnected);
      communication.current.removeListener(CommunicationSignalingEvent.ParticipantRejectedCall, onPeerRejected);
      communication.current.removeListener(CommunicationSignalingEvent.ParticipantCallTimedOut, onPeerTimedOut);
      communication.current.removeListener(CommunicationSignalingEvent.ParticipantCallFailed, onPeerFailed);
      communication.current.removeListener(CommunicationSignalingEvent.ParticipantIsBusy, onPeerBusy);
      communication.current.removeListener(CommunicationSignalingEvent.ParticipantIsOnDoNotDisturbState, onPeerOnDoNotDisturb);
      communication.current.removeListener(CommunicationSignalingEvent.NeedToRenderParticipant, onNeedToRenderParticipant);
      communication.current.removeListener(CommunicationSignalingEvent.GotVideoSize, onPeerVideoSize);
    };
  }, []);

  const iterateConnectedDevices = (callback: (deviceId: string) => void) => {
    connectedParticipants?.forEach(participant => {
      participant.devicesIds?.forEach(deviceId => {
        callback(deviceId);
      });
    });
  };

  const setInitialVolumeValues = () => {
    // var device = new NucleusDevice(call.deviceId);
    // device.setSpeakerVolume(1.0); //device.setSpeakerVolume(1.0)
    // device.setMicGain(0.5); //device.setMicGain(0.5)
    console.warn('onMicSlideComplete');
    iterateConnectedDevices(deviceId => {
      signaling.current.sendUserDataMessageToPeer(
        deviceId as unknown as SignalingId, //Cast to signalingId
        JSON.stringify({
          VOLUME: 1.0,
          MIC_VOLUME: 0.5,
        }),
      );
    });
  };

  if (!InCall) {
    console.log('render connecting screen');
    return <RingingScreen />;
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        // display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#fff',
      }}
    >
      <CallPlayer
        callee={{
          entityId: call.entityId,
          name: call.callerName,
          firstName: call.calleeFirstName,
          lastName: call.calleeLastName,
          host: true,
          type: call.type,
          status: 'connected',
        }}
        participants={connectedParticipants}
        {...{ onCall, onCallFailed }}
      />

      <div
        style={{
          backgroundColor: '#82CEFE',
          height: '8vh',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 20,
          width: '100%',
          color: '#fff',
        }}
      >
        {!call.multiCall && (
          <span style={{ fontSize: 20 }}>
            {i18n.t('current-call', {
              calleeName: call.patientFirstName + ' ' + call.patientLastName,
            })}
          </span>
        )}
        {call.multiCall && (
          <span style={{ fontSize: 20 }}>
            {i18n.t('current-call', {
              stateParticipantNames,
            })}
          </span>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: 20,
          }}
        >
          {/* MUTE BUTTON */}
          <CallButton
            defaultValue={stateInitialMute}
            text={i18n.t('mute-btn')}
            customIcon={
              <MuteSVG
                defaultValue={stateInitialMute}
                onClick={status => {
                  console.log('mute icon', status);
                  status ? NucleusCallView.muteAudio() : NucleusCallView.unmuteAudio();
                  setStateInitialMute(status);
                }}
              />
            }
            activeIcon={'/img/mute-button.png'}
          />
          {/* MUTE BUTTON */}

          {/* END CALL BUTTON */}
          <CallButton
            text={i18n.t('end-call-btn')}
            customIcon={
              <EndCallSVG
                onClick={() => {
                  console.log('end call button');
                  endCall();
                }}
              />
            }
          />
          {/* END CALL BUTTON */}
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <CustomSlider
              initialValue="100"
              id="volBar"
              ref={volBar}
              minImg="/img/call_mute_icon.png"
              maxImg="/img/call_volume_icon.png"
              min="1"
              max="100"
              step="1"
              onChange={handleVolBar}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
