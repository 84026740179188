import NucleusSearchInput from 'components/NucleusSearchInput';
import NucleusTable from 'components/NucleusTable';
import { UIAvatar, useModal } from 'components/UI';
import { UIButton } from 'components/UI/Buttons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import authStore from 'stores/AuthStore';
import CareUserStore from 'stores/CareUserStore';
import message from 'utils/Message';
import { fetchAvailablePatients, removeUserPatient } from './assignmentActions';
import EditUserAssignedPatients from './EditUserAssignedPatients';

export type AssignedPatient = {
  id: string;
  firstName: string;
  lastName: string;
  telephone: string;
  assignedToUser: boolean;
  assignedToUsersGroup: boolean;
  groupNames: string;
  thumbnailUrl: string;
};
const UserAssignedPatients = () => {
  const params = useParams<{ userId: string }>();
  const [searchText, setSearchText] = useState<string>('');
  const [UserAssignedPatients, setUserAssignedPatients] = useState<AssignedPatient[]>([]);

  useEffect(() => {
    fetchPatients();
  }, [params?.userId, CareUserStore.getUserAllPatientsAccess()]);


  const fetchPatients = useCallback(() => {
    if (params.userId && !(CareUserStore.getUserAllPatientsAccess().toString() == 'true')) {
      fetchAvailablePatients({
        limit: 1000,
        page: 1,
        userId: params.userId,
        cb: ({ response, success }) => {
          onGetPossiblePatientsAction(response, success);
        },
      });
    }
  }, [params?.userId, CareUserStore.getUserAllPatientsAccess()]);

  const onGetPossiblePatientsAction = (response, success) => {
    if (response && success) {
      setUserAssignedPatients(response.patients);
    } else {
      message.show('Error getting available ' + authStore.getPatientLabelPlural().toLowerCase() + ' information');
    }
  };
  const { 
    isOpen: isEditOpen, 
    openModal: openEditModal, 
    closeModal: closeEditModal 
  } = useModal();

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: ({ row }) => {
          return (
            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <UIAvatar size="small" src={row.original?.thumbnailUrl || ''} alt="avatar" style={{ marginRight: 10 }} />
              <span style={{ marginLeft: 5 }}>
                {row.original.firstName} {row.original.lastName}
              </span>
            </span>
          );
        },
      },
      {
        Header: 'Telephone',
        accessor: 'telephone',
      },
      {
        Header: 'Group',
        accessor: 'groupNames',
      },
      {
        accessor: 'id',
        showSort: false,
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span style={{ minHeight: 55, minWidth: 100 }}>
                {row.original.assignedToUser && !row.original.assignedToUsersGroup && (
                  <UIButton
                    text="Remove"
                    variant="clear-danger"
                    onClick={() => {
                      removeUserPatient({
                        patientId: row.original.id,
                        userId: params.userId,
                        cb({ success, message: textMessage }) {
                          if (success) {
                            message.show(textMessage);
                            fetchPatients();
                          } else {
                            message.show(textMessage);
                          }
                        },
                      });
                    }}
                  />
                )}
              </span>
            </div>
          );
        },
      },
    ];
    return cols;
  }, [params.userId, fetchPatients]);

  return (
    <div
      style={{
        paddingTop: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }}>
          <NucleusSearchInput
            value={searchText}
            onChange={e => {
              const value = e.target.value;
              setSearchText(value);
            }}
            placeholder={`Filter by ${authStore.getPatientLabel()} Name`}
          />
        </div>
        <div style={{}}>
          <UIButton
            showChevron
            onClick={() => {
              openEditModal();
            }}
            text="Edit Assignments"
            variant="secondary"
          />
        </div>
      </div>
      <div style={{}}>
        <NucleusTable
          columns={columns}
          data={UserAssignedPatients.filter(
            user => user.firstName.toLowerCase().includes(searchText.toLowerCase()) || user.lastName.toLowerCase().includes(searchText.toLowerCase()),
          )}
        />
      </div>
      <div>
        <EditUserAssignedPatients fetchUserAssignedPatients={fetchPatients} closeModal={closeEditModal} isOpen={isEditOpen} patientsList={UserAssignedPatients} />
      </div>
    </div>
  );
};

export default UserAssignedPatients;
