import moment from 'moment-timezone';
import styled from 'styled-components';
import { Chevron } from './assets/Chevron';
import { VideoIcon } from './assets/VideoIcon';
import { ProviderCallRequest, ProviderCallRequestStatus } from './queue.types';

const ProviderQueueRowContainer = styled.div`
  border-radius: 5px;
  display: flex;
`;

const LeftComponent = styled.div<{ background: string }>(
  props => `
  display: flex;
  flex-direction: row;
  background: ${props.background};
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 10px;
  flex: 1;
`,
);

const RightComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;
  cursor: pointer;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  color: #0a313f;
  font-size: 16px;
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
`;

const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: center;
  text-align: center;
  flex: 0;
  padding-left: 5px;
  padding-right: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
const ColumnTopLine = styled.div`
  margin-bottom: 7px;
`;
const Title = styled.div<{ escalated?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: ${(props: { escalated?: boolean }) => (props.escalated ? '#520614' : '#0092FF')};
`;

const ColumnBody = styled.div`
  display: flex;
  flex-direction: column;
  color: #0a313f;
  flex: 1;
  // justify-content: center;
`;

const CallRequestStatusLabel = styled.div`
  color: #fe3824;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-width: 60px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  color: #0092ff;
`;

const IconBox = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
`;

const ColumnIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  margin-right: 5px;
  cursor: pointer;
`;

export const ProviderQueueRow = ({ callRequest, markAsHandled, onClick }: { callRequest: ProviderCallRequest; onClick: () => void; markAsHandled: () => void }) => {
  const normalizedHandledTime = callRequest.HandledTime ? moment(callRequest.HandledTime) : undefined;
  const timeInQueue = moment(normalizedHandledTime).to(moment(callRequest.RequestTime));

  let callRowStatusBackground = '#EBF2FE';

  switch (callRequest.CallStatus) {
    case ProviderCallRequestStatus.Canceled:
    case ProviderCallRequestStatus.Declined:
    case ProviderCallRequestStatus.Failed:
    case ProviderCallRequestStatus.NotAnswered:
    case ProviderCallRequestStatus.Busy:
      callRowStatusBackground = '#FFEAA6';
      break;
  }
  if (callRequest.EscalatedAt) callRowStatusBackground = '#FFBCB7';

  return (
    <ProviderQueueRowContainer>
      <LeftComponent background={callRowStatusBackground}>
        <Row>
          <Column>
            <ColumnTopLine>
              <Title escalated={!!callRequest.EscalatedAt}>Account Name:</Title>
              <ColumnBody>{callRequest.AccountName}</ColumnBody>
            </ColumnTopLine>
            <Title escalated={!!callRequest.EscalatedAt}>State:</Title>
            <ColumnBody>{callRequest.AccountState}</ColumnBody>
          </Column>
          <Column>
            {callRequest.RequestedByUserID && (
              <ColumnTopLine>
                <Title escalated={!!callRequest.EscalatedAt}>Caller:</Title>
                <ColumnBody>{callRequest.RequestedByUserName}</ColumnBody>
              </ColumnTopLine>
            )}

            <Title escalated={!!callRequest.EscalatedAt}>Patient Name:</Title>
            <ColumnBody>
              {callRequest.PatientFirstName} {callRequest.PatientLastName}
            </ColumnBody>
          </Column>
          <Column>
            <Title escalated={!!callRequest.EscalatedAt}>Reason:</Title>
            <ColumnBody>{callRequest.CallReason}</ColumnBody>
          </Column>
          <Column>
            <Title escalated={!!callRequest.EscalatedAt}>Doctor:</Title>
            <ColumnBody>{callRequest.CallAttemptedBy}</ColumnBody>
          </Column>
          <Column>
            <Title escalated={!!callRequest.EscalatedAt}>First Requested:</Title>
            {moment(callRequest.RequestTime).format('MM/DD/YYYY hh:mm A zz')}
          </Column>
          <Column>
            <Title escalated={!!callRequest.EscalatedAt}>Time in Queue:</Title>
            {timeInQueue}
          </Column>
          <Column
            style={{
              flex: 0,
              //justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <Title escalated={!!callRequest.EscalatedAt}>Requests:</Title>
            {callRequest.NumOfRequests}
          </Column>
          <ColumnWrap>
            <CallRequestStatusLabel>{callRequest.CallStatus}</CallRequestStatusLabel>
          </ColumnWrap>
          <ColumnIcon onClick={onClick}>
            <VideoIcon />
          </ColumnIcon>
        </Row>
      </LeftComponent>
      <RightComponent onClick={() => markAsHandled()}>
        <IconContainer>
          Mark as handled
          <IconBox>
            <Chevron />
          </IconBox>
        </IconContainer>
      </RightComponent>
    </ProviderQueueRowContainer>
  );
};
