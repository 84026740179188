//@ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import AuthStore from '../../stores/AuthStore';
import CaregiverStore from '../../stores/CaregiverStore';
import CareUserStore from '../../stores/CareUserStore';

import GeneralStore from '../../stores/GeneralStore';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';
import { SalesforceSyncButton } from './components/SalesforceIntegration';
import UserItemRow from './UserItemRow';
// import CaregiverItemRow from "../../components/CaregiverItemRow";

class Users extends React.Component<
  {
    history?: any;
  },
  {
    usersArray: any[];
    currentUserID: string;
    coordinatorsContent: string;
    caregiversContent: string;
    coordinatorsTab: string;
    caregiversTab: string;

    caregiversArray: any[];
    currentCaregiverID: string;

    btnNewCaregiverVisibility: string;
    btnSendSMSVisibility: string;

    smsNames: string;
    btnStyle: string;
    udpateDate?: Date;
  }
> {
  smsReceivers: number;
  smsSent: number;

  constructor(props) {
    super(props);

    validateAccessGranted('/users');

    this.state = {
      usersArray: [],
      currentUserID: '',
      coordinatorsContent: '',
      caregiversContent: 'hide',
      coordinatorsTab: 'staffTab',
      caregiversTab: '',

      caregiversArray: [],
      currentCaregiverID: '',

      btnNewCaregiverVisibility: '',
      btnSendSMSVisibility: 'hide',

      smsNames: '',
      btnStyle: ' nucleus-submit-btn-disabled',
    };
    this.onGetAllUsersDataAction = this.onGetAllUsersDataAction.bind(this);
    this.openDeleteUserModalAction = this.openDeleteUserModalAction.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.onDeleteUserAction = this.onDeleteUserAction.bind(this);
    this.handleCoordinatorsTab = this.handleCoordinatorsTab.bind(this);
    this.handleCaregiversTab = this.handleCaregiversTab.bind(this);

    this.handleSendSMSModal = this.handleSendSMSModal.bind(this);
    this.onGetAllCaregiversDataAction = this.onGetAllCaregiversDataAction.bind(this);
    this.onSMSListHasChangedAction = this.onSMSListHasChangedAction.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSendSMS = this.handleSendSMS.bind(this);

    this.checkSMSMessage = this.checkSMSMessage.bind(this);
    this.onSendCaregiverSMSAction = this.onSendCaregiverSMSAction.bind(this);

    this.smsReceivers = 0;
    this.smsSent = 0;
  }

  componentDidMount() {
    //Register Listener
    CareUserStore.on('onGetAllUsersData', this.onGetAllUsersDataAction);
    GeneralStore.on('openDeleteUserModalAction', this.openDeleteUserModalAction);
    CareUserStore.on('onDeleteUserAction', this.onDeleteUserAction);

    CaregiverStore.on('onGetAllCaregiversData', this.onGetAllCaregiversDataAction);
    CaregiverStore.on('onSMSListHasChanged', this.onSMSListHasChangedAction);
    CaregiverStore.on('onSendCaregiverSMS', this.onSendCaregiverSMSAction);

    //Get all the Users Array Data from WS
    CareUserStore.getAllUsersData({
      UserID: AuthStore.getUserID(),
    });
    // CaregiverStore.getAllCaregiversData({
    //     AccountID : AuthStore.getUserAccountID()
    // });
    $('.tooltipped').tooltip({ delay: 50 });

    if (GeneralStore.getStaffTab() != 1) {
      this.handleCaregiversTab();
    }

    // DEPRECATED: We removed the caregivers functionality
    // if (CaregiverStore.getSMSList() > 0){
    //   this.setState({
    //     btnNewCaregiverVisibility : 'hide',
    //     btnSendSMSVisibility : ''
    //   });
    // }
    // CaregiverStore.clearCaregiverSMSList();

    this.setState({
      btnNewCaregiverVisibility: '',
      btnSendSMSVisibility: 'hide',
    });

    $('.modalMultiSMS').modal({
      dismissible: true,
      complete: function () {}, // Callback for Modal close
    });
  }

  componentWillUnmount() {
    //Remove Listener
    //*-
    CareUserStore.removeListener('onGetAllUsersData', this.onGetAllUsersDataAction);
    GeneralStore.removeListener('openDeleteUserModalAction', this.openDeleteUserModalAction);
    CareUserStore.removeListener('onDeleteUserAction', this.onDeleteUserAction);

    CaregiverStore.removeListener('onGetAllCaregiversData', this.onGetAllCaregiversDataAction);
    CaregiverStore.removeListener('onSMSListHasChanged', this.onSMSListHasChangedAction);
    CaregiverStore.removeListener('onSendCaregiverSMS', this.onSendCaregiverSMSAction);

    $('.tooltipped').tooltip('remove');
  }

  onGetAllUsersDataAction(success) {
    //*-
    this.setState({
      usersArray: [],
    });
    if (success) {
      this.setState({
        usersArray: CareUserStore.getAllUsers(),
      });
    } else {
      Message.show('Sorry, There was a problem getting the users information. Please try again.');
    }
    //console.log(this.state.usersArray);
  }

  onGetAllCaregiversDataAction(success) {
    this.setState({
      caregiversArray: [],
    });
    if (success) {
      this.setState({
        caregiversArray: CaregiverStore.getAllCaregivers(),
      });
    } else {
      Message.show('Sorry, There was a problem getting the ' + AuthStore.getCaregiverLabelPlural().toLowerCase() + ' information. Please try again.');
    }
  }

  openDeleteUserModalAction(UserID) {
    this.setState({
      currentUserID: UserID,
    });
    // console.log("Delete: "+this.state.currentPatientID);
    // console.log("Delete: "+PatientID);
    $('#modalDeleteUser').modal('open');
  }

  handleCancelDelete() {
    $('#modalDeleteUser').modal('close');
  }

  handleDeleteUser() {
    CareUserStore.DeleteUser({
      UserID: this.state.currentUserID,
    });
  }

  onDeleteUserAction(response) {
    if (response.ok) {
      $('#modalDeleteUser').modal('close');
      CareUserStore.getAllUsersData({
        UserID: AuthStore.getUserID(),
      });
      setTimeout(function () {
        Message.show('User deleted successfully.');
      }, 700);
    } else {
      $('#modalDeleteUser').modal('close');
      Message.show('Sorry, There was a problem deleting the User. Please try again.');
    }
  }

  handleCoordinatorsTab() {
    this.setState({
      coordinatorsContent: '',
      caregiversContent: 'hide',
      coordinatorsTab: 'staffTab',
      caregiversTab: '',
    });
    GeneralStore.setStaffTab(1);

    CareUserStore.getAllUsersData({
      UserID: AuthStore.getUserID(),
    });
    //Message.show(AuthStore.getUserLabelPlural() + " information updated.");
  }

  handleCaregiversTab() {
    this.setState({
      coordinatorsContent: 'hide',
      caregiversContent: '',
      coordinatorsTab: '',
      caregiversTab: 'staffTab',
    });
    GeneralStore.setStaffTab(2);

    CaregiverStore.getAllCaregiversData({
      AccountID: AuthStore.getUserAccountID(),
    });
    //Message.show(AuthStore.getCaregiverLabelPlural() + " information updated.");
  }

  handleSendSMSModal() {
    $('#modalNewSMS').modal('open');
    //@ts-ignore
    this.refs.textSMSMessage.value = '';
    this.setState({
      smsNames: CaregiverStore.getSMSNames(),
      btnStyle: ' nucleus-submit-btn-disabled',
    });
  }

  handleCloseModal() {
    $('#modalNewSMS').modal('close');
  }

  checkSMSMessage() {
    //@ts-ignore
    if (this.refs.textSMSMessage.value.length > 0) {
      this.setState({
        btnStyle: ' nucleus-submit-btn',
      });
    } else {
      this.setState({
        btnStyle: ' nucleus-submit-btn-disabled',
      });
    }
  }

  handleSendSMS() {
    //@ts-ignore
    CaregiverStore.setSMSMessage(this.refs.textSMSMessage.value);
    this.smsSent = 0;

    // this.setState({
    //   messageToSend : this.refs.textSMSMessage.value,
    //   messageList : CaregiverStore.getSMSListData()
    // });
    $('#modalNewSMS').modal('close');
    //window.location.assign("#/newMessage");
    //@ts-ignore
    const message = this.refs.textSMSMessage.value;
    const messageReceiversArray = CaregiverStore.getSMSListData();

    console.log(message);
    console.log(messageReceiversArray);

    for (let i = 0; i < messageReceiversArray.length; i++) {
      console.log('sending message to ' + messageReceiversArray[i].ID + ', ' + messageReceiversArray[i].Name);
      CaregiverStore.sendSMS({
        CareGiverID: messageReceiversArray[i].ID,
        Message: message,
      });
    }
  }

  onSMSListHasChangedAction(list) {
    this.smsReceivers = list;
    if (list > 0) {
      this.setState({
        btnNewCaregiverVisibility: 'hide',
        btnSendSMSVisibility: '',
      });
    } else {
      this.setState({
        btnNewCaregiverVisibility: '',
        btnSendSMSVisibility: 'hide',
      });
    }
  }

  onSendCaregiverSMSAction(response) {
    console.log('onSendCaregiverSMSAction ', this.smsReceivers);
    console.log(response);

    this.smsSent = this.smsSent + 1;
    if (this.smsSent >= this.smsReceivers) {
      this.setState({
        caregiversArray: [],
        udpateDate: new Date(),
      });
      if (this.smsReceivers == 1) {
        Message.show('Message sent!');
      } else {
        Message.show('Messages sent!');
      }
      CaregiverStore.clearCaregiverSMSList();
      this.setState({
        caregiversArray: CaregiverStore.getAllCaregivers(),
        btnNewCaregiverVisibility: '',
        btnSendSMSVisibility: 'hide',
        udpateDate: new Date(),
      });
    }
  }

  render() {
    const usersList = [];
    if (this.state.usersArray != null) {
      this.state.usersArray.map((user, key) => {
        usersList.push(
          <UserItemRow
            index={key}
            key={key}
            UserID={user.UserID}
            UserImageThumb={user.UserImageThumb}
            FirstName={user.FirstName}
            LastName={user.LastName}
            Email={user.Email}
            Telephone={user.Telephone}
            IsAdmin={user.IsAdmin}
            IsOnDuty={user.IsOnDuty}
            Status={user.Created}
          />,
        );
      });
    }

    const caregiversList = [];

    const adminAccessButtons = AuthStore.getUserRol() == 'admin' ? ' ' : ' hide';

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">Staff</p>
          <div className="row no-margin a-bit-lower">
            <div className="col s8 m8 no-margin left-align">
              <a className={'staffTabs nucleus-link ' + this.state.coordinatorsTab} onClick={this.handleCoordinatorsTab}>
                &nbsp;&nbsp;{AuthStore.getUserLabelPlural()}&nbsp;&nbsp;
              </a>
            </div>
            <div className="col s4 m4 no-margin content-right-aligned">
              <SalesforceSyncButton accountId={AuthStore.getUserAccountID()} />
            </div>
          </div>
          {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a className={"staffTabs nucleus-link " + this.state.caregiversTab } onClick={this.handleCaregiversTab}>&nbsp;&nbsp;{ AuthStore.getCaregiverLabelPlural() }&nbsp;&nbsp;</a> */}

          <div className={'users-table-pending altoImportantContainer ' + this.state.coordinatorsContent}>
            <div className="row">
              <div className="col s12 m12 l12 right-align">
                {/*
                            <Link to="/newUser" >
                                <span className="nucleus-link-light nucleus-font-medium">&nbsp; New &nbsp;</span>
                            </Link>
                            <a className="nucleus-link-light nucleus-font-medium tooltipped" data-position="top" data-tooltip="Feature currently not available">&nbsp; Upload CSV &nbsp;</a>
                            <a className="nucleus-link-light nucleus-font-medium tooltipped" data-position="top" data-tooltip="Feature currently not available">&nbsp; Download  CSV &nbsp;</a>
                            */}
              </div>
            </div>

            <div className={'fixed-action-btn btn-add-new ' + adminAccessButtons}>
              <Link id="add-newuser" to="/newUser">
                <span
                  className="btn-floating btn-large waves-effect waves-light orange tooltipped"
                  data-position="left"
                  data-delay="50"
                  data-tooltip={'New ' + AuthStore.getUserLabel()}
                >
                  <i className="material-icons">add</i>
                </span>
              </Link>
              &nbsp;
            </div>

            <div className="row">
              <table className="bordered highlight  nucleus-table">
                <thead>
                  <tr>
                    <th className="table-col-25">
                      <span className="nucleus-table-header">Name</span>
                    </th>
                    <th className="table-col-30">
                      <span className="nucleus-table-header">Email</span>
                    </th>
                    <th className="table-col-20">
                      <span className="nucleus-table-header">Telephone</span>
                    </th>
                    <th className="table-col-10">
                      <span className="nucleus-table-header">Status</span>
                    </th>
                    <th className="table-col-5">
                      <span className="nucleus-table-header">Admin</span>
                    </th>
                    <th className="table-col-10 center-align">
                      <span className="nucleus-table-header">{AuthStore.getOnDutyLabel()}</span>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>{usersList}</tbody>
              </table>
            </div>
          </div>

          <div className={'caregivers-table-pending altoImportantContainer ' + this.state.caregiversContent}>
            <div className="row">
              <div className="col s12 right-align">
                {/*
                            <Link to="/newCaregiver" >
                                <span className="nucleus-link-light nucleus-font-medium">&nbsp; New &nbsp;&nbsp;</span>
                            </Link>
                            <a className="nucleus-link-light nucleus-font-medium tooltipped" data-position="top" data-tooltip="Feature currently not available">&nbsp; Upload CSV &nbsp;</a>
                            <a className="nucleus-link-light nucleus-font-medium tooltipped" data-position="top" data-tooltip="Feature currently not available">&nbsp; Download  CSV &nbsp;</a>
                          */}
              </div>
            </div>

            <div className="fixed-action-btn">
              <Link id={'add-newcaregiver'} to="/newCaregiver">
                <span
                  className={'btn-floating btn-large waves-effect waves-light orange tooltipped ' + this.state.btnNewCaregiverVisibility}
                  data-position="left"
                  data-delay="50"
                  data-tooltip={'New ' + AuthStore.getCaregiverLabel()}
                >
                  <i className="material-icons">add</i>
                </span>
              </Link>
              <a
                className={'btn-floating btn-large waves-effect waves-light green tooltipped ' + this.state.btnSendSMSVisibility}
                data-position="left"
                data-delay="50"
                data-tooltip="New Message"
                onClick={this.handleSendSMSModal}
              >
                <i className="material-icons">message</i>
              </a>
              &nbsp;
            </div>

            {/* 
                <div className="nucleus-table-container">
                */}
            <div className="row">
              <table className="bordered highlight  nucleus-table">
                <thead>
                  <tr>
                    <th>
                      <span className="nucleus-table-header">Name</span>
                    </th>
                    <th>
                      <span className="nucleus-table-header">Email</span>
                    </th>
                    <th>
                      <span className="nucleus-table-header">Telephone</span>
                    </th>
                    <th>
                      <span className="nucleus-table-header">Next Visit</span>
                    </th>
                    <th>
                      <span className="nucleus-table-header">{AuthStore.getPatientLabel()} Name</span>
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>{caregiversList}</tbody>
              </table>
            </div>
          </div>

          <div id="modalNewSMS" className="modal modalMultiSMS ">
            <div className="modal-content nucleus-modal-content txt-special-color">
              <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseModal} />
              <h3 className="nucleus-page-subtitle">New Message</h3>
              <div className="row"></div>

              <div className="row no-margin">
                <span className="nucleus-font-medium">
                  <b>To: </b>
                </span>{' '}
                {this.state.smsNames}
              </div>
              <br />
              <div className="input-field col s12 modalCallLogContent2 no-margin">
                <textarea ref="textSMSMessage" className="nucleus-textarea-big " defaultValue={''} onChange={this.checkSMSMessage}></textarea>
                <br />
                <div className="row no-margin a-bit-lower">
                  <div className="col s12 center-align no-margin">
                    {/*
                                    <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCloseModal}>Cancel</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    */}
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.btnStyle} onClick={this.handleSendSMS}>
                      Send
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*
                <div id="modalDeleteUser" className="modal modalDeleteUser">
                  <div className="modal-content modal-content-delete">
                      <h3 className="nucleus-page-subtitle"> Delete User</h3>
                      <div className="row">
                          <div className="col s12 left-align nucleus-font-medium"> 
                            Are you sure you want to remove this user?
                            <br/> 
                          </div>
                      </div>
                      <div className="row ">
                          <div className="col s12 right-align ">
                              <p></p>
                              <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelDelete}>Cancel</a>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleDeleteUser}>Confirm</a>
                          </div>
                      </div>
                  </div>
                </div>
                */}
        </div>
      </div>
    );
  }
}

export default Users;
