import { reportCallStartedPromise, reportMultiCallStartedPromise } from '@nucleus-care/nucleuscare-backend-client';
import { getAccountDevicesPromise } from '@nucleus-care/nucleuscare-backend-client';

import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import api from 'utils/httpClient';
import NucleusNetwork from 'utils/NucleusNetwork';
import Config from '../Config';
class PatientApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<----------------------------------Get All Patients Data--------------------------->
  //<-------------------------------------  DEPRECATED -------------------------------->
  getAllPatientData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatients',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------Get All Patients Data By User------------------------->
  getAllPatientDataForUser(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatientsForUser',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Get User Data-------------------------------->
  GetCarePatientData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatientProfileData',
      data: data,
      callback: callback,
    });
  }

  GetPatientBiometricData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatientBiometricData',
      data: data,
      callback: callback,
    });
  }

  GetPatientCallLogs(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatientCallLogs',
      data: data,
      callback: callback,
    });
  }

  GetPatientRequestLogs(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatientRequestCallLogs',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Update Patient Data----------------------------->
  UpdateCarePatientData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatient',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Update Patient Image----------------------------->
  UploadCarePatientImage(data, callback) {
    const body = new FormData();
    body.append('PatientID', data.PatientID);
    body.append('File', data.File);
    api({
      url: '/CarePatient.asmx/UploadPicture',
      method: 'post',
      data: body,
    })
      .then(response => {
        callback(response.data);
      })
      .catch(e => {
        callback(e.response.data);
      });
  }

  UpdatePatientZipCode(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateZipCode',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Create New Patient--------------------------->
  CreateNewPatient(data, callback) {
    try {
      NucleusNetwork.request({
        context: this.context,
        type: 'POST',
        url: Config.domain + 'CarePatient.asmx/AddPatient',
        data: data,
        callback: callback,
      });
    } catch (ex) {
      callback({
        ok: false,
        error: 'Network Error' + ex.message,
      });
    }
  }

  //<---------------------------------Delete Patient--------------------------------->
  DeletePatient(data, callback) {
    let rsp;
    httpNodeApi
      .delete(`patient/${data.PatientID}/delete`)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('patient/:patientId/delete response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on patient/:patientId/delete' };
        console.log('Error patient/:patientId/delete error', data);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('patient/:patientId/delete finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  //<-----------------------Get Available Devices for Patient----------------------->
  GetCareDevices(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareDevice.asmx/GetDevices',
      data: data,
      callback: callback,
    });
  }

  //<-----------------------Assign Device To Patient ----------------------->
  AssignDevicePatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/AssignPatientDevice',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------- Update Device Name ------------------------->
  UpdateDeviceName(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareDevice.asmx/UpdateDeviceName',
      data: data,
      callback: callback,
    });
  }

  //<-----------------------Unassign Device To Patient ----------------------->
  UnassignDevicePatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareDevice.asmx/UnassignPatientDevice',
      data: data,
      callback: callback,
    });
  }
  //<-----------------------Request Reboot Device ----------------------->
  RequestRebootDevice(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareDevice.asmx/RebootDevice',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------Report CareCall Started ----------------------->
  async ReportCareCallStarted(data) {
    return reportCallStartedPromise(data);
  }

  ReportMultiCallStarted(data, callback) {
    console.log('ReportMultiCallStarted', data);
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/ReportMultiCallStarted",
    //   data: data,
    //   callback: callback,
    // });
    reportMultiCallStartedPromise(data)
      .then(data => {
        callback(data);
      })
      .catch(e => {
        console.error('Error ReportMultiCallStarted: ', e);
        callback({
          ok: false,
        });
      });
  }

  //<-------------------------Report CareCall Ended ----------------------->
  async ReportCareCallEnded(data) {
    const { data: response } = await httpNodeApi.post('v2/call/report_connected_call_ended', data);
    console.log('v2/call/report_connected_call_ended response', response);
    return response;
  }

  ReportCanceledCall(data, callback) {
    console.log('ReportCanceledCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_canceled_call', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('v2/call/report_canceled_call response', response);
        const { data } = response;
        rsp = {
          ...data,
          ok: true,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_canceled_call' };
        console.log('Error v2/call/report_canceled_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_canceled_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportFailedCall(data, callback) {
    console.log('ReportFailedCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_failed_call', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('v2/call/report_failed_call response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_failed_call' };
        console.log('Error v2/call/report_failed_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_failed_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportNotAnsweredCall(data, callback) {
    console.log('ReportNotAnsweredCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_not_answered_call', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('call/report_not_answered_call response', response);
        const { data } = response;
        rsp = {
          ...data,
          ok: true,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_not_answered_call' };
        console.log('Error v2/call/report_not_answered_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_not_answered_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportBusyCall(data, callback) {
    console.log('ReportBusyCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_busy_call', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('v2/call/report_busy_call response', response);
        const { data } = response;
        rsp = {
          ...data,
          ok: true,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_busy_call' };
        console.log('Error call/report_busy_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_busy_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportCallDoNotDisturb(data, callback) {
    console.log('ReportCallDoNotDisturb params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_dnd_call', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('v2/call/report_dnd_call response', response);
        const { data } = response;
        rsp = {
          ...data,
          ok: true,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_dnd_call' };
        console.log('Error v2/call/report_dnd_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_dnd_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportDeclinedCall(data, callback) {
    console.log('ReportDeclinedCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_declined_call', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('v2/call/report_declined_call response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_declined_call' };
        console.log('Error call/report_declined_call error', data);
        rsp = {
          ...data,
          ok: true,
        };
      })
      .finally(() => {
        console.log('v2/call/report_declined_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  ReportDisconnectedCall(data, callback) {
    console.log('ReportDisconnectedCall params', data);
    let rsp;
    httpNodeApi
      .post('v2/call/report_disconnected_call', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('v2/call/report_disconnected_call response', response);
        const { data } = response;
        rsp = {
          ...data,
          ok: true,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_disconnected_call' };
        console.log('Error v2/call/report_disconnected_call error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('v2/call/report_disconnected_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  //<-------------------------Report CareCall Ended ----------------------->
  ReportCareCallEndStatus(data, callback) {
    console.log('ReportCareCallEndStatus', Config.domain + 'CareCall.asmx/' + data.Endpoint);
    console.log('ReportCareCallEndStatus data', data);
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareCall.asmx/' + data.Endpoint,
      data: data,
      callback: callback,
    });
  }

  //<---------------------------Get Call Log Notes --------------------------->
  GetCallLogNotes(data, callback) {
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/GetCallLogNotes",
    //   data: data,
    //   callback: callback,
    // });

    let rsp;
    httpNodeApi
      .post('call_log/get_notes', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('call_log/get_notes response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/get_notes' };
        console.log('Error call_log/get_notes error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/get_notes finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  GetCallLogDetailsData(data, callback) {
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/GetCallLogDetailsData",
    //   data: data,
    //   callback: callback,
    // });
    let rsp;
    httpNodeApi
      .post('call_log/get_data', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('call_log/get_data response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/get_data' };
        console.log('Error call_log/get_data error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/get_data finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  GetCallRequestLogDetailsNotes(data, callback) {
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/GetCallRequestLogDetails",
    //   data: data,
    //   callback: callback,
    // });

    let rsp;
    httpNodeApi
      .post('call_log/get_request_notes', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('call_log/get_request_notes response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/get_request_notes' };
        console.log('Error call_log/get_request_notes error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/get_request_notes finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  GetCallRequestLogDetailsData(data, callback) {
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/GetCallRequestLogDetailsData",
    //   data: data,
    //   callback: callback,
    // });
    let rsp;
    httpNodeApi
      .post('call_log/get_request_data', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('call_log/get_request_data response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/get_request_data' };
        console.log('Error call_log/get_request_data error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/get_request_data finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  //<-----------------------Update Call Log Notes --------------------------->
  UpdateCallLogNotes(data, callback) {
    let rsp;
    httpNodeApi
      .put('call_log/' + data.CallLogID + '/update_notes', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('call_log/:callLogId/update_notes response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/:callLogId/update_notes' };
        console.log('Error call_log/:callLogId/update_notes error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/:callLogId/update_notes finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/UpdateCallLogNotes",
    //   data: data,
    //   callback: callback,
    // });
  }

  UpdateCallRequestLogNotes(data, callback) {
    let rsp;
    httpNodeApi
      .put('call_log/' + data.CallRequestLogID + '/update_request_notes', data)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        console.log('call_log/:callLogId/update_request_notes response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call_log/:requestLogId/update_request_notes' };
        console.log('Error call_log/:callLogId/update_request_notes error', data);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call_log/:callLogId/update_request_notes finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
    // NucleusNetwork.request({
    //   context: this.context,
    //   type: "POST",
    //   url: Config.domain + "CareCall.asmx/UpdateCallRequestLogNotes",
    //   data: data,
    //   callback: callback,
    // });
  }

  //<-----------------------Get Patient Devices --------------------------->
  GetPatientDevices(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatientDevices',
      data: data,
      callback: callback,
    });
  }

  //<-----------------------Get Patient Assignments --------------------------->
  GetPatientAssignments(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/ListAssignments',
      data: data,
      callback: callback,
    });
  }

  UpdateAssignment(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareGiver.asmx/UpdateAssignment',
      data: data,
      callback: callback,
    });
  }

  //<-----------------------Get Patient FamilyMembers --------------------------->
  // GetPatientFamilyMembers(data, callback) {
  //   NucleusNetwork.request({
  //     context: this.context,
  //     type: "POST",
  //     url: Config.domain + "FamilyMember.asmx/List",
  //     data: data,
  //     callback: callback,
  //   });
  // }
  // DEPRECATED: Jan 26th 2022, Moved to Node Backend.

  CheckExistingTelephone(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'FamilyMember.asmx/IsAlreadyExists',
      data: data,
      callback: callback,
    });
  }

  //<-----------------------Create New Family Member --------------------------->
  CreateNewFamilyMember(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'FamilyMember.asmx/Add',
      data: data,
      callback: callback,
    });
  }

  AssignPatientMember(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'FamilyMember.asmx/AddToPatient',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------Update Family Member ----------------------------->
  UpdateFamilyMember(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'FamilyMember.asmx/UpdateProfileFromWeb',
      data: data,
      callback: callback,
    });
  }

  UpdateFamilyMemberName(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'FamilyMember.asmx/UpdateName',
      data: data,
      callback: callback,
    });
  }

  UpdateFamilyMemberTelephone(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'FamilyMember.asmx/UpdateTelephone',
      data: data,
      callback: callback,
    });
  }

  //<--------------------------Delete Family Member --------------------------->
  DeleteFamilyMember(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'FamilyMember.asmx/Delete',
      data: data,
      callback: callback,
    });
  }

  //<--------------------------------Request Resend Email----------------------------->
  RequestResendInvite(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'FamilyMember.asmx/ResendInvite',
      data: data,
      callback: callback,
    });
  }

  //<--------------------------Get Patient Notes ------------------------------>
  GetPatientNotes(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatientNotes',
      data: data,
      callback: callback,
    });
  }
  //<--------------------------Add Patient Note ------------------------------>
  AddPatientNote(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/AddPatientNote',
      data: data,
      callback: callback,
    });
  }

  //<------------------------Update Patient Note ------------------------------>
  UpdatePatientNote(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientNote',
      data: data,
      callback: callback,
    });
  }

  //<------------------------Delete Patient Note ------------------------------>
  DeletePatientNote(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/DeletePatientNote',
      data: data,
      callback: callback,
    });
  }

  //<----------------------Update Patient Message ---------------------------->
  UpdatePatientMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/UpdatePatientMessage',
      data: data,
      callback: callback,
    });
  }

  //<-----------------------Delete Patient Message ------------------------------>
  DeletePatientMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/DeletePatientMessage',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------Send Message to Device -------------------------->
  // Monday Januray 8th, 2018 -> DEPRECATED
  SendMessageToDevice(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/SendMessageToDevice',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------Send Message to Patient -------------------------->
  SendMessageToPatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/SendMessageToPatient',
      data: data,
      callback: callback,
    });
  }

  //<---------------------Send Message to All Patients ----------------------->
  SendBroadcastMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/SendMessageToAllPatients',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------Get Patient Messages ---------------------------->
  GetPatientMessages(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetPatientMessages',
      data: data,
      callback: callback,
    });
  }

  GetDeliveredPatientMessages(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetDeliveredMessagesForPatient',
      data: data,
      callback: callback,
    });
  }

  GetScheduledPatientMessages(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetScheduledMessagesForPatient',
      data: data,
      callback: callback,
    });
  }

  // DEPRECATED - Fri Dec 6th 2019
  // https://nucleusintercom.atlassian.net/browse/CARE-6239

  // GetPatientButtons(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BLEButton.asmx/GetButtonsForPatient",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  // GetUnAssignedAccountButtons(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BLEButton.asmx/GetUnassignedButtonsForAccount",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  // GetAccountButtons(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BLEButton.asmx/GetButtonsForAccount",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  // AssignButtonToPatient(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BLEButton.asmx/AssignButtonToPatient",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  // UpdateButton(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BLEButton.asmx/UpdateButtonLocation",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  // UnAssignButtonToPatient(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BLEButton.asmx/RemoveButtonFromPatient",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  // DEPRECATED Nov 20th, 2019
  // https://nucleusintercom.atlassian.net/browse/CARE-6159
  // GetPatientBiometricDevices(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BiometricDevices.asmx/GetBiometricDevicesForPatient",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  // GetUnAssignedBioDevicesForAccount(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BiometricDevices.asmx/GetUnassignedButtonsForAccount",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }
  // AssignBiometricDeviceToPatient(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BiometricDevices.asmx/AssignBiometricDeviceToDevice",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }
  // UpdateBiometricDevice(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BiometricDevices.asmx/UpdateBiometricDevice",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }
  // UnAssignPatientBiometricDevice(data,callback) {
  // 	NucleusNetwork.request({
  // 		context : this.context,
  // 		type : "POST",
  // 		url: Config.domain + "BiometricDevices.asmx/RemoveBiometricDeviceFromDevice",
  // 		data : data,
  // 		callback : callback,
  // 	});
  // }

  UpdateDeviceVolume(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareDevice.asmx/UpdateRingtoneVolume',
      data: data,
      callback: callback,
    });
  }

  // DEPRECATED: November 2nd 2021
  // UpdateDeviceConcierge(data, callback) {
  //   NucleusNetwork.request({
  //     context: this.context,
  //     type: "POST",
  //     url: Config.domain + "CareDevice.asmx/UpdateConcierge",
  //     data: data,
  //     callback: callback,
  //   });
  // }

  UpdateDeviceCallShortcut(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareDevice.asmx/UpdateDeviceCallShortcut',
      data: data,
      callback: callback,
    });
  }

  //Config

  GetLocalesData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareCs.asmx/GetAccountLocale',
      data: data,
      callback: callback,
    });
  }

  // * * * * * * * * * * * * * * * * * * * * * * * * *
  UpdatePatientConfigString(data, endpoint, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + endpoint,
      data: data,
      callback: callback,
    });
  }

  // * * * * * * * * * * * * * * * * * * * * * * * * *
  UpdatePatientConfigFlag(data, endpoint, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + endpoint,
      data: data,
      callback: callback,
    });
  }

  UpdateShowCallButton(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowCallButton',
      data: data,
      callback: callback,
    });
  }

  UpdateShowCallButton2(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowCallButton2',
      data: data,
      callback: callback,
    });
  }

  UpdateShowCheckInButton(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowCheckInButton',
      data: data,
      callback: callback,
    });
  }

  UpdateShowEmergencyButton(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowEmergencyButton',
      data: data,
      callback: callback,
    });
  }

  UpdateShowWeather(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowWeather',
      data: data,
      callback: callback,
    });
  }

  UpdateShowCallControls(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateShowCallButtonsBar',
      data: data,
      callback: callback,
    });
  }

  UpdateCallPhoneFromDevice(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateCallPhoneFromDevice',
      data: data,
      callback: callback,
    });
  }

  deleteFamilyPhotos(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/DeleteFamilyPhotos',
      data: data,
      callback: callback,
    });
  }

  deleteFamilyMembers(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/DeleteFamilyMembers',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableMemoryBox(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableMemoryBox',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableBlankMemoryBox(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateBlankEnableMemoryBox',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableExternalApps(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableExternalApps',
      data: data,
      callback: callback,
    });
  }

  UpdateZipCode(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateZipCode',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableBiometrics(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableBiometrics',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableBluetoothButtons(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableBluetoothButtons',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableAutoAnswer(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableAutoAnswer',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableAppIncomingCall(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableAppIncomingCall',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableImpairedRingtone(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableImpairedRingtone',
      data: data,
      callback: callback,
    });
  }

  UpdateFamilyEnableAutoAnswer(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateFamilyEnableAutoAnswer',
      data: data,
      callback: callback,
    });
  }

  UpdateFamilyAdmin(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateFamilyAdmin',
      data: data,
      callback: callback,
    });
  }

  UpdateBloodPressureMetrics(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientBloodPressureAlert',
      data: data,
      callback: callback,
    });
  }

  UpdateOxiMeterMetrics(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientOxiMeterAlert',
      data: data,
      callback: callback,
    });
  }

  UpdateScaleMetrics(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientScaleAlert',
      data: data,
      callback: callback,
    });
  }

  UpdateTemperatureMetrics(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientTemperatureAlert',
      data: data,
      callback: callback,
    });
  }

  UpdateBloodSugarAlert(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientBloodSugarAlert',
      data: data,
      callback: callback,
    });
  }

  UpdateHoursSleptAlert(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdatePatientHoursSleptAlert',
      data: data,
      callback: callback,
    });
  }

  //Patient Schedule
  GetPatientSchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatientSchedule',
      data: data,
      callback: callback,
    });
  }

  AddNewPatientSchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareSchedule.asmx/AddPatientSchedule',
      data: data,
      callback: callback,
    });
  }

  EditPatientSchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareSchedule.asmx/UpdatePatientSchedule',
      data: data,
      callback: callback,
    });
  }

  RemovePatientSchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareSchedule.asmx/RemovePatientSchedule',
      data: data,
      callback: callback,
    });
  }

  CopySchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareSchedule.asmx/AddDefaultScheduleToPatient',
      data: data,
      callback: callback,
    });
  }

  ResetPatientUseSchedule(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareSchedule.asmx/ResetPatientUseSchedule',
      data: data,
      callback: callback,
    });
  }

  UpdateEnableVoiceMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/UpdateEnableVoiceMessage',
      data: data,
      callback: callback,
    });
  }

  GetAccountDevices(data, callback) {
    // DEPRECATED
    // NucleusNetwork.request({
    // 	context : this.context,
    // 	type : "POST",
    // 	url: Config.domain + "CareCs.asmx/GetAccountDevices",
    // 	data : data,
    // 	callback : callback,
    // });
    getAccountDevicesPromise(data.AccountID)
      .then(data => {
        callback(data);
      })
      .catch(e => {
        console.log('CarePatientStore GetAccountDevices Error: ', e);
        callback({
          ok: false,
        });
      });
  }

  ClearPatientCache(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'BulletinBoard.asmx/ClearPatientCache',
      data: data,
      callback: callback,
    });
  }
}

export default PatientApi;
