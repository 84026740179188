import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
  label?: string;
  checked: boolean;
  onClick: () => void;
}

interface CheckboxProps {
  tagId: string;
  label?: string;
  checked: boolean;
  onClick: (isChecked: boolean) => void;
}

export const UICheckbox: FC<Props> = ({ label, checked, onClick }) => {
  return (
    <Container
      onClick={e => {
        e.stopPropagation();
        if (onClick) onClick();
      }}
    >
      <CheckboxInput className="filled-in" type="checkbox" checked={checked} />
      <Label>{label}</Label>
    </Container>
  );
};

export const UICheckboxLine: FC<CheckboxProps> = ({ tagId, label, checked, onClick }) => {
  const inputId = 'Checkbox_' + (tagId ? tagId.replaceAll(' ', '') : Math.floor(Math.random() * 100));
  const handleCheckboxChange = () => {
    onClick(!checked);
  };

  return (
    <CheckboxContainer>
      <CheckboxLabel>
        <Label>{label}</Label>
      </CheckboxLabel>
      <CheckboxWrapper>
        <Checkbox>
          <input type="checkbox" id={inputId} className="filled-in" checked={checked} onChange={handleCheckboxChange} />
          <label htmlFor={inputId}></label>
        </Checkbox>
      </CheckboxWrapper>
    </CheckboxContainer>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const CheckboxLabel = styled.div`
  flex: 1;
  margin: 0;
`;

const Checkbox = styled.span``;

const CheckboxWrapper = styled.div`
  height: 20px;
  margin: 0;
`;

const Label = styled.label`
  font-size: 16px;
  color: #0a313f;
`;
