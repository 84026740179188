import Config from '../Config';
import NucleusNetwork from '../utils/NucleusNetwork';

class BulletinApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<-------------------- Get BulletinBoard Buttons by Account --------------------->
  GetAccountBulletinButtons(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'BulletinBoard.asmx/GetAccountBulletinButtons',
      data: data,
      callback: callback,
    });
  }

  //<---------------------------Update Media Caption--------------------------->
  UpdateButtonContent(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'BulletinBoard.asmx/UpdateBulletinButtonContent',
      data: data,
      callback: callback,
    });
  }

  UploadImage(data, callback) {
    //try{
    NucleusNetwork.requestWithFile({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'BulletinBoard.asmx/UploadBulletinImage',
      data: data,
      callback: callback,
    });
    // } catch(ex){
    // 	callback({ok:false, error : ex});
    // }
  }

  UploadPdf(data, callback) {
    //try{
    NucleusNetwork.requestWithFile({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'BulletinBoard.asmx/UploadBulletinPdfFile',
      data: data,
      callback: callback,
    });
    // } catch(ex){
    // 	callback({ok:false, error : ex});
    // }
  }
}

export default BulletinApi;
