import { endCall } from '@nucleus-care/nucleuscare-backend-client';

import type { FullCallEntity, WebRTCCall } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { RootState } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/store';
import { NucleusCommunicationSignaling, MultiCallType, CallType } from '@nucleus-care/nucleuscare-connect-signaling';
import { CallApi } from 'apis/CallApi';
import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import AuthStore from 'stores/AuthStore';
import CareCommunicationStore from 'stores/CareCommunicationStore';
import { JQuery } from 'types/jquery.types';
import Message from 'utils/Message';
import { v4 as uuidv4 } from 'uuid';
import { CallingMethods, WebRTCCallView } from '../WebRTCCallView/WebRTCCallView';
import RingingAvatar from './containers/RingingAvatar';

const communicationSingleton = NucleusCommunicationSignaling.getInstance();

let CallEstablished = false;
let showEndedMessage = false;
let CallLogID: string | null = '';
let retryBusy = false;
let callStartedReported = false;
let PendingReportCallStartedResponse = false;
let ShowEndedMessage = false;
let localTimeout: NodeJS.Timeout;
let callAnswered = false;
let patientPendingRequestLogID = '';
let patientIDsArray = [];
let patientDeviceIDsArray = [];

const Communication = () => {
  const endCallBtnSrc = '/img/endVideoCall.png';
  const endAudioCallBtnSrc = '/img/endAudioCall.png';

  const call = useSelector((state: RootState) => (state.call ? state.call.call : undefined));

  const dispatch = useDispatch();
  const [customCall, setCustomCall] = useState<WebRTCCall>(undefined);

  const onReportCallEstablishedAction = response => {
    console.log('onReportCallEstablishedAction', response);
  };

  const onReportCallInProgressAction = response => {
    console.log('onReportCallInProgressAction', response);
  };

  useEffect(() => {
    //@ts-ignore
    $('.nucleus-modal-call').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
  }, []);
  // Conol

  useEffect(() => {
    CareCommunicationStore.on('onCallStartedReported', onCallStartedReportedAction);
    CareCommunicationStore.on('onReportCallEstablished', onReportCallEstablishedAction);
    CareCommunicationStore.on('onReportCallInProgress', onReportCallInProgressAction);

    return () => {
      CareCommunicationStore.removeListener('onCallStartedReported', onCallStartedReportedAction);

      CareCommunicationStore.removeListener('onReportCallEstablished', onReportCallEstablishedAction);

      CareCommunicationStore.removeListener('onReportCallInProgress', onReportCallInProgressAction);

      communicationSingleton.disposeCurrentCall();
    };
  }, []);

  useEffect(() => {
    if (call) {
      CallLogID = uuidv4();

      CareCommunicationStore.updateCallInProgress(true);

      if (call.multiCall) {
        console.log('-> call.multiCall');

        let tempCall: WebRTCCall = {} as WebRTCCall;

        const callData = { ...call };
        const patientsArray: FullCallEntity[] = [];
        patientIDsArray = [];
        patientDeviceIDsArray = [];
        delete callData['classParticipants'];
        call.classParticipants?.forEach(patient => {
          patientsArray.push({
            entityId: patient.ID,
            name: patient.Name,
            firstName: patient.FirstName,
            lastName: patient.LastName,
            type: call.type,
            status: 'connected',
            host: false,
            devicesIds: [patient.DeviceID],
          });
          patientIDsArray.push(patient.ID);
          patientDeviceIDsArray.push(patient.DeviceID);
        });

        tempCall = {
          ...callData,
          entityId: AuthStore.getUserID(),
          //@ts-ignore
          deviceId: AuthStore.getUserID(),
          user: AuthStore.getUser(),
          type: call.type,
          MultiCallType: MultiCallType.Class,
          CallLogID,
          //Refactor this to support multiple call participants
          participants: patientsArray,
          status: 'connected',
        };
        setCustomCall(tempCall);
        console.log('custom call obj ======>', tempCall);
      } else {
        let tempCall: WebRTCCall = {} as WebRTCCall;

        tempCall = {
          ...call,
          entityId: AuthStore.getUserID(),
          type: call.type,
          callLogId: CallLogID,
          multiCall: false,
          timeoutInSeconds: 62,
          callingMethod: call.callingMethod as CallingMethods,
        };
        setCustomCall(tempCall);
        console.log('custom call obj ======>', tempCall);
      }

      //@ts-ignore
      $('#modalNucleusCallCom').modal('open');
      clearCallTimeout();
      localTimeout = setTimeout(() => {
        if (communicationSingleton.currentCall != null) {
          communicationSingleton.timeoutCurrentCall();
        } else {
          console.log('useEffect - handleCallTimeoutAction');
          handleCallTimeoutAction();
        }
      }, 30000);
    } else {
      console.log('dissmiss call', call);
      CareCommunicationStore.updateCallInProgress(false);
      setCustomCall(undefined);
      clearCallTimeout();
      //@ts-ignore
      $('#modalNucleusCallCom').modal('close');
    }
  }, [call]);

  const clearCallSelector = () => {
    console.log('dispatch(endCall())');
    dispatch(endCall());
  };

  const reportCallStarted = async () => {
    if (!call) {
      console.warn('reportCallStarted call is null');
      return;
    }
    // Listen to "onReportCallStartedAction"
    console.log('reportCallStarted ', call);
    if (!call) {
      console.warn('reportCallStarted call is null');
      return;
    }

    CallEstablished = true;

    if (!call.multiCall) {
      const numberOfParticipants = call.participants.length;
      const isSingleDevice = numberOfParticipants === 1 && call.participants[0].devicesIds.length === 1;
      const deviceId = isSingleDevice ? call.participants[0].devicesIds[0] : undefined;
      await CareCommunicationStore.reportCallStarted({
        CallLogID: CallLogID!,
        entityId: call.patientId,
        PatientID: call.patientId,
        DeviceID: deviceId,
        UserID: AuthStore.getUserID()!,
        RequestID: call.requestId,
        CallType: call.type,
      });
    } else {
      console.log('reportMultiCallStarted call', call);
      console.log('reportMultiCallStarted patientIDsArray', patientIDsArray);
      const patientIDs = patientIDsArray.join(',');
      const devicesIDs = patientDeviceIDsArray.join(',');
      console.log('Final Array', patientIDs);
      console.log('Final ClassSessionID', call.classSessionId);
      const entityIds = [call.entityId].join(',');
      CareCommunicationStore.reportMultiCallStarted({
        CallLogID: CallLogID!,
        entityIds: entityIds,
        UserID: AuthStore.getUserID()!,
        ClassID: call.classSessionId,
        PatientIDs: patientIDs,
        DeviceIDs: devicesIDs,
        CallType: call.type,
      });
    }
  };

  const onCallStartedReportedAction = response => {
    console.log('onReportCallStartedAction == >', response, retryBusy, call);

    if (response.ok) {
      patientPendingRequestLogID = response.CallLogID;

      if (retryBusy) {
        reportCallEndedWithStatus(CareCommunicationStore.Status.BUSY);
        retryBusy = false;
      }

      callStartedReported = true;
      PendingReportCallStartedResponse = false;
    } else {
      //Message.show("There was a problem reporting the call status");
      //this.handleCloseCall();
      clearCallTimeout();
      localTimeout = setTimeout(() => {
        console.log('onCallStartedReportedAction - handleCallTimeoutAction');
        handleCallTimeoutAction('There was a problem reporting the call status');
      }, 10000);
    }
  };

  const clearCallTimeout = () => {
    if (localTimeout != null) {
      clearTimeout(localTimeout);
    }
  };

  const reportCallEstablished = () => {
    if (!call) {
      console.warn('reportCallEstablished call is null');
      return;
    }
    console.log('reportCallEstablished');

    console.log('reportCallEstablished requestID', call.requestId);
    console.log('reportCallEstablished patientPendingRequestLogID', patientPendingRequestLogID);

    if (call.requestId?.length >= 32) {
      CareCommunicationStore.reportCallEstablished({
        RequestID: call.requestId,
        UserID: AuthStore.getUserID()!,
        entityId: AuthStore.getUserID()!,
      });
    }

    if (patientPendingRequestLogID?.length >= 32) {
      CareCommunicationStore.reportCallInProgress({
        CallLogID: patientPendingRequestLogID,
      });
    }
  };

  const handleCloseCall = async () => {
    console.log('handleCloseCall CLOSE', CallEstablished, callAnswered);

    if (CallEstablished) {
      if (callAnswered) {
        await reportCallEnded(callAnswered);
        //window.parent.showStatus("Call Ended");
        Message.show('Call ended');
        console.log('call reported Ended');
        // $("#status").text("Call Ended");
        // $("#status").show();
        console.log('show Message of end call');

        //    Message.show("Call ended");
        console.log('showEndedMessage ==>', showEndedMessage);
        showEndedMessage = true;
        console.log('showEndedMessage ==> 2', showEndedMessage);

        if (communicationSingleton.isCallEstablished()) {
          communicationSingleton.endCurrentCall();
        }
      } else {
        try {
          communicationSingleton.cancelCurrentCall();
          handleCallCanceledAction(true);
        } catch (ex) {
          console.log('handleCloseCall CLOSE 5', ex);
        }
      }

      CallEstablished = false;
    } else {
      communicationSingleton.cancelCurrentCall();
      handleCallCanceledAction(true);
    }

    setTimeout(() => {
      CareCommunicationStore.updateCallInProgress(false);
      CareCommunicationStore.refreshIncomingCallBarStatus();

      callAnswered = false;
    }, 1000);

    ($('#modalNucleusCallCom') as unknown as JQuery).modal('close');
    clearCallSelector();
  };

  const reportCallEndedWithStatus = async Status => {
    if (!call) {
      console.warn('reportCallEndedWithStatus call is null');
      return;
    }
    // Listen to "onReportCallEndedAction"
    console.log('reportCallEndedWithStatus', Status);
    console.log('reportCallEndedWithStatus patientPendingRequestLogID', patientPendingRequestLogID);
    console.log('reportCallEndedWithStatus CallLogID', CallLogID);
    console.log('Check 2');
    if (!PendingReportCallStartedResponse && call) {
      if (!call.multiCall) {
        await CareCommunicationStore.reportCallEndedStatus({
          CallLogID: patientPendingRequestLogID || CallLogID!,
          PatientID: call.patientId,
          entityId: call.entityId,
          Status,
        });
      } else {
        CareCommunicationStore.reportMultiCallEndedStatus({
          CallLogID: patientPendingRequestLogID || CallLogID!,
          PatientID: '',
          entityId: call.entityId,
          Status,
        });
      }
    } else {
      //this.PendingReportCallEnded = true;
      //Message.show("Ending call");
    }
    patientPendingRequestLogID = '';
  };
  const reportCallEnded = async wasAnswered => {
    if (!call) {
      console.warn('reportCallEnded call is null');
      return;
    }
    // Listen to "onReportCallEndedAction"
    console.log('reportCallEnded', wasAnswered, PendingReportCallStartedResponse);
    try {
      if (!PendingReportCallStartedResponse) {
        if (!call.multiCall) {
          await CareCommunicationStore.reportCallEnded({
            CallLogID: patientPendingRequestLogID,
            WasAnswered: wasAnswered,
            PatientID: call.patientId,
            AverageAvailableKbps: 0,
            entityId: call.entityId,
          });

          patientPendingRequestLogID = '';
          console.log('end >', showEndedMessage, '>>', ShowEndedMessage);
          if (showEndedMessage) {
            Message.show('Call ended');
          }
        } else {
          CareCommunicationStore.reportMultiCallEnded({
            CallLogID: patientPendingRequestLogID,
            WasAnswered: wasAnswered,
            PatientID: '',
            AverageAvailableKbps: '',
          });
        }
      }
    } catch (e) {
      Message.show('There was an error reporting the call status');
    }
  };

  const handleCallEstablishedAction = () => {
    console.log('handleCallEstablishedAction ShouldMute');

    callAnswered = true;
    clearCallTimeout();
    reportCallEstablished();
  };

  const handleCallRejectedAction = () => {
    console.log('ESTABLISHED REJECTED', CallEstablished);
    if (CallEstablished) {
      ShowEndedMessage = false;
      showEndedMessage = false;

      reportCallEndedWithStatus(CareCommunicationStore.Status.DECLINED);
    } else {
      setTimeout(() => {
        //@ts-ignore
        $('#modalNucleusCallCom').modal('close');
      }, 1200);
    }
    clearCallSelector();

    setTimeout(() => {
      //@ts-ignore
      $('#modalNucleusCallCom').modal('close');
    }, 1000);
    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();

    ////CallEstablished = false;

    Message.show('The call was declined');
  };

  const handleCallDisconnectedAction = () => {
    console.log('handleCallDisconnectedAction DISCONNECTED', CallEstablished);

    CallLogID = null;

    showEndedMessage = false;
    if (CallEstablished) {
      console.log('SET VAR>>2');

      //reportCallEnded(true);
      reportCallEndedWithStatus(CareCommunicationStore.Status.DISCONNECTED);
      CallEstablished = false;

      if (communicationSingleton.currentCall != null) communicationSingleton.endCurrentCall();
      // Message.show("Call ended");
      Message.show('Call Disconnected');
    }
    clearCallSelector();
    setTimeout(() => {
      callAnswered = false;

      CareCommunicationStore.updateCallInProgress(false);
      CareCommunicationStore.refreshIncomingCallBarStatus();

      patientPendingRequestLogID = '';
    }, 1000);

    setTimeout(() => {
      //@ts-ignore
      $('#modalNucleusCallCom').modal('close');
    }, 1200);

    ////CallEstablished = false;
  };

  const handleEndCall = async () => {
    console.log('handleEndCall END', CallEstablished);

    if (CallEstablished) {
      console.log('SET VAR>>99');
      ShowEndedMessage = false;
      showEndedMessage = false;
      await reportCallEnded(callAnswered);
      try {
        if (communicationSingleton.isCallEstablished()) {
          communicationSingleton.endCurrentCall();
        }
      } catch (ex) {
        console.log('handleCloseCall CLOSE 5', ex);
      }
      CallEstablished = false;

      Message.show('Call ended');
      clearCallSelector();
    }

    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();

    callAnswered = false;

    patientPendingRequestLogID = '';

    ($('#modalNucleusCallCom') as unknown as JQuery).modal('close');

    ////CallEstablished = false;
  };

  const handleCallFailedAction = () => {
    console.log('CALL FAILED', CallEstablished);

    if (communicationSingleton) {
      communicationSingleton.endCurrentCall();
    }

    ShowEndedMessage = false;
    showEndedMessage = false;
    clearCallSelector();

    clearCallTimeout();
    setTimeout(() => {
      //@ts-ignore
      $('#modalNucleusCallCom').modal('close');
    }, 1200);
    PendingReportCallStartedResponse = false;
    showEndedMessage = false;
    reportCallEndedWithStatus(CareCommunicationStore.Status.FAILED);
    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();

    Message.show('Call Failed');
  };

  const handleCallTimeoutAction = (msg = 'The call was not answered') => {
    console.log('Communication ESTABLISHED TIMEOUT', CallEstablished);

    if (CallEstablished) {
      console.log('SET VAR>>3');
      ShowEndedMessage = false;
      showEndedMessage = false;

      // reportCallEnded(false);
      reportCallEndedWithStatus(CareCommunicationStore.Status.NOT_ANSWERED);
    }

    clearCallSelector();

    setTimeout(() => {
      //@ts-ignore
      $('#modalNucleusCallCom').modal('close');
    }, 1200);
    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();

    ////CallEstablished = false;
    Message.show(msg);
  };

  const handleCallBusyAction = () => {
    if (!customCall) {
      console.warn('handleCallBusyAction customCall is null');
      return;
    }
    console.log('CALL BUSY', CallEstablished, callStartedReported, call);

    ShowEndedMessage = false;
    showEndedMessage = false;

    PendingReportCallStartedResponse = false;
    // if (callStartedReported) {
    setTimeout(() => {
      //Preventive timeout so the reportCallStarted never goes after the call busy
      reportCallEndedWithStatus(CareCommunicationStore.Status.BUSY);
    }, 100);

    //} else {
    // retryBusy = true;
    // }
    setTimeout(() => {
      //@ts-ignore
      $('#modalNucleusCallCom').modal('close');
    }, 1200);
    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();
    //If we get a userName we use it, if not we use the patient name
    const patientName = call?.userName || customCall.patientFirstName + ' ' + customCall.patientLastName;
    Message.show('Sorry, ' + patientName + ' is currently busy with another call.');
    clearCallSelector();
  };

  const handleCallDoNotDisturbAction = () => {
    if (!customCall) {
      console.warn('handleCallDoNotDisturbAction customCall is null');
      return;
    }
    console.log('CALL DO_NOT_DISTURB', CallEstablished, callStartedReported, call);

    ShowEndedMessage = false;
    showEndedMessage = false;

    PendingReportCallStartedResponse = false;
    // if (callStartedReported) {
    setTimeout(() => {
      //Preventive timeout so the reportCallStarted never goes after the call busy
      reportCallEndedWithStatus(CareCommunicationStore.Status.DO_NOT_DISTURB);
    }, 100);

    //} else {
    // retryBusy = true;
    // }
    setTimeout(() => {
      //@ts-ignore
      $('#modalNucleusCallCom').modal('close');
    }, 1200);
    CareCommunicationStore.updateCallInProgress(false);
    CareCommunicationStore.refreshIncomingCallBarStatus();

    // We need to implement the payload on DND to know which device rejected
    // the call becuause of DND
    const deviceName = call.deviceName ? ' - ' + call.deviceName : '';
    Message.show('Sorry, ' + (customCall.patientFirstName + deviceName) + ' is currently in Do Not Disturb mode.');
    clearCallSelector();
  };

  const handleCallCanceledAction = async dontClose => {
    console.log('handleCallCanceledAction');

    showEndedMessage = false;
    showEndedMessage = false;
    reportCallEndedWithStatus(CareCommunicationStore.Status.CANCELED);

    if (call?.callingMethod === CallingMethods.Notify) {
      await CallApi.cancelCall({
        userId: AuthStore.getUserID()!,
        entityId: call.entityId!,
        requestCallLogId: call.requestId!,
        callType: call.type as 'Video' | 'Audio',
        callLogId: CallLogID!,
      });
    }

    !dontClose &&
      setTimeout(() => {
        console.log('handleCallCanceledAction modalNucleusCallCom');
        CareCommunicationStore.updateCallInProgress(false);
        CareCommunicationStore.refreshIncomingCallBarStatus();
      }, 500);

    !dontClose && ($('#modalNucleusCallCom') as unknown as JQuery).modal('close');
    clearCallSelector();

    //Message.show("The call was canceled");
  };

  const RingingScreen = useCallback(() => {
    if (!customCall) {
      console.warn('RingingScreen customCall is null');
      return null;
    }
    let customType = 'Video Call';

    switch (customCall.type) {
      case CallType.BargeIn:
        customType = 'Auto Answer';
        break;
      case CallType.SilentBargeIn:
        customType = 'Silent Auto Answer';
        break;
      case CallType.Video:
        customType = 'Video Call';
        break;
      case CallType.Audio:
        customType = 'Audio Call';
        break;
    }

    const initials =
      customCall.patientFirstName && customCall.patientLastName ? customCall.patientFirstName.substring(0, 1) + '' + customCall.patientLastName.substring(0, 1) : 'Temp';

    const deviceName = call?.deviceName ? ' - ' + call?.deviceName : '';
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <span className={'staffTabs txt-black  center-align callModeTitle '} style={{ fontWeight: '700' }}>
            {' '}
            {customCall.patientFirstName + ' ' + customCall.patientLastName + deviceName}
          </span>
          <span
            className={'staffTabs txt-black  center-align CustomCallModeSubtitle '}
            style={{
              marginBottom: 140,
              fontWeight: '300',
            }}
          >
            {' '}
            {customType}
          </span>
          <RingingAvatar patientInitials={initials} />
        </div>

        <div className="control-panels center-align">
          <img
            id={'close-end-call'}
            className="responsive-img nucleus-link nucleus-call-control"
            src={customCall.type == 'Audio' ? endAudioCallBtnSrc : endCallBtnSrc}
            onClick={handleCloseCall}
          />
          <br />
          <label className="txt-white call-control-title"> CANCEL CALL</label>
        </div>
      </div>
    );
  }, [CallLogID]);

  const onNotifyCall = async () => {
    console.log('onNotifyCall', call);
    if (call?.entityId) {
      await CallApi.notifyCall({
        userId: AuthStore.getUserID()!,
        entityId: call.entityId,
        requestCallLogId: call.requestId!,
        callType: call.type as 'Video' | 'Audio',
        callLogId: CallLogID!,
      });
      reportCallStarted();
    }
  };

  const onCallInitiated = () => {
    console.log('onCallInitiated ==>', call);
    if (call?.callingMethod === CallingMethods.Notify) {
      console.log('onCallInitiated Notify -- Accept current call', communicationSingleton.currentCall);
      communicationSingleton.acceptCurrentCall();
    }
  };

  return (
    <div id="modalNucleusCallCom" className="modal nucleus-modal-call modal-custom" data-backdrop="false" style={{ zIndex: 250 }}>
      <div className="modal-content nucleus-modal-content-call" style={{}}>
        {customCall && (
          <WebRTCCallView
            call={customCall}
            onNotifyCall={onNotifyCall}
            callingMethod={customCall.callingMethod as CallingMethods}
            RingingScreen={RingingScreen}
            {...{
              onCallRejected: handleCallRejectedAction,
              onCallDisconnected: handleCallDisconnectedAction,
              onCallCanceled: handleCallCanceledAction,
              onCallTimeout: handleCallTimeoutAction,
              onCallBusy: handleCallBusyAction,
              onCallDoNotDisturb: handleCallDoNotDisturbAction,
              onCallFailed: handleCallFailedAction,
              onCallStarted: reportCallStarted,
              onCallEstablished: handleCallEstablishedAction,
              CallLogID: patientPendingRequestLogID,
              onCallInitiated: onCallInitiated,
              onCallEnded: handleEndCall,
              onCallHandled: clearCallTimeout,
            }}
          />
        )}
      </div>
      <style>
        {`
            .modal-custom {
              max-height : 100%;
              top : 0% !important;
              position : absolute !important;
            }

            // .nucleus-hard-container {
            //   margin : 0;
            //   padding : 0;
            // }
          `}
      </style>
    </div>
  );
};

export default Communication;
