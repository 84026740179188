import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { JQuery } from 'types/jquery.types';
import Config from '../../Config';
import AuthStore from '../../stores/AuthStore';
import CarePatientStore from '../../stores/CarePatientStore';
import GeneralStore from '../../stores/GeneralStore';

import { validateAccessGranted } from '../../utils/accessGrantedValidator';

import Message from '../../utils/Message';
import PatientProfileEdit from './PatientProfileEdit';
import PatientProfileInfo from './PatientProfileInfo';

class PatientProfile extends React.Component<
  {
    match: any;
    history: any;
  },
  {
    currentMode: string;
    linkModeLabel: string;
    patientID: string;
    patientFirstName: string;
    patientLastName: string;
    patientFullName: string;
    patientEmail: string;
    patientBirthDate: string;
    patientTelephone: string;
    patientAddress: string;
    patientAge: string;
    patientZipCode: string;
    emergencyContactName: false;
    emergencyContactTelephone: false;
    deviceMacAddress: string;
    deviceStatus: string;
    devices: any[];
    callLogs: any[];
    callLogID: string;
    callLogStartTime: string;
    callLogStatus: string;
    callLogDuration: string;
    callLogParticipants: any[];
    callLogRequest?: any;
    callLogNotes: string;
    ImageThumb: string;

    pendingRequest: string | null | undefined;
    //autoCallDeviceID: null,

    userAdmin: boolean;

    removeBtnState: string;

    callLogModalBtnState: string;
    callLogModalTxtState: string;
  }
> {
  constructor(props) {
    super(props);

    validateAccessGranted('/patientProfile');
    console.log('PATIENT PROFILE');
    const { match } = this.props;

    this.state = {
      currentMode: 'Info',
      linkModeLabel: 'Edit',
      patientID: '',
      patientFirstName: '',
      patientLastName: '',
      patientFullName: '',
      patientEmail: '',
      patientBirthDate: '',
      patientTelephone: '',
      patientAddress: '',
      patientAge: ' ',
      patientZipCode: ' ',
      emergencyContactName: false,
      emergencyContactTelephone: false,
      deviceMacAddress: 'Empty',
      deviceStatus: '',
      devices: [],
      callLogs: [],
      callLogID: '',
      callLogStartTime: '',
      callLogStatus: '',
      callLogDuration: '',
      callLogParticipants: [],
      callLogRequest: null,
      callLogNotes: '',
      ImageThumb: '/img/user_placeholder.png',

      pendingRequest: match.params.pendingRequest,
      //autoCallDeviceID: null,

      userAdmin: AuthStore.getUserRol() == 'admin',

      removeBtnState: 'nucleus-submit-btn-small',

      callLogModalBtnState: 'nucleus-submit-btn',
      callLogModalTxtState: '',
    };

    this.onPatientGetDataAction = this.onPatientGetDataAction.bind(this);
    this.onGetPatientDevicesAction = this.onGetPatientDevicesAction.bind(this);
    this.handleProfileMode = this.handleProfileMode.bind(this);
    this.handleRemovePatientModal = this.handleRemovePatientModal.bind(this);
    this.onUpdatePatientAction = this.onUpdatePatientAction.bind(this);
    this.onUnassignDevicePatientAction = this.onUnassignDevicePatientAction.bind(this);
    this.onOpenCallLogDetailsAction = this.onOpenCallLogDetailsAction.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenParticipantStats = this.handleOpenParticipantStats.bind(this);
    this.handleSaveNotes = this.handleSaveNotes.bind(this);
    this.onUpdateCallLogNotesAction = this.onUpdateCallLogNotesAction.bind(this);

    this.onGetCallRequestLogDetails2Action = this.onGetCallRequestLogDetails2Action.bind(this);

    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.handleDeletePatient = this.handleDeletePatient.bind(this);
    this.onDeletePatientAction = this.onDeletePatientAction.bind(this);
    this.copyPatientIdToClipboard = this.copyPatientIdToClipboard.bind(this);

    this.onRequestCancelEditProfileModeAction = this.onRequestCancelEditProfileModeAction.bind(this);

    this.autoCallDeviceID = '';
    this.autoCallMode = '0';

    console.log('Le PendingRequest', match.params.pendingRequest);
    if (match.params.pendingRequest != null && match.params.pendingRequest.length > 10) {
      const start = match.params.pendingRequest.substring(0, 1);
      console.log(start);
      if (start == '_') {
        const deviceAutoCallID = match.params.pendingRequest.substring(1);
        //_61cbd3aa-f60a-493b-82a5-6fe0cd0319b7
        console.log('deviceAutoCallID', deviceAutoCallID);
        this.autoCallDeviceID = deviceAutoCallID;
        // this.setState({
        //     autoCallDeviceID : deviceAutoCallID
        // });
      } else {
        console.warn('*********========= you need to add _ for a call Important!!!!!!!!');
      }
    }
    if (match.params.autoCallMode != null && match.params.autoCallMode.length > 0) {
      this.autoCallMode = match.params.autoCallMode;
    }
    console.log('Le Call Mode', this.autoCallMode);
  }

  componentDidMount() {
    const { match } = this.props;
    console.log('PatientProfile');
    console.log(match.params.patientId);
    if (match.params.patientId != null) {
      //Get the User Data from WS
      CarePatientStore.getPatientData({
        PatientID: match.params.patientId,
      });
      CarePatientStore.getPatientDevices({
        PatientID: match.params.patientId,
      });

      CarePatientStore.getPatientBiometricData({
        PatientID: match.params.patientId,
      });
    } else {
      this.props.history.push('/patients');
    }

    //Register Listener
    CarePatientStore.on('onPatientGetData', this.onPatientGetDataAction);
    CarePatientStore.on('onGetPatientDevices', this.onGetPatientDevicesAction);
    CarePatientStore.on('onUpdatePatientAction', this.onUpdatePatientAction);
    CarePatientStore.on('onUnassignDevicePatientAction', this.onUnassignDevicePatientAction);
    CarePatientStore.on('onOpenCallLogDetailsAction', this.onOpenCallLogDetailsAction);
    CarePatientStore.on('onUpdateCallLogNotesAction', this.onUpdateCallLogNotesAction);

    CarePatientStore.on('onGetCallRequestLogDetails2', this.onGetCallRequestLogDetails2Action);

    CarePatientStore.on('onDeletePatientAction', this.onDeletePatientAction);
    GeneralStore.on('onRequestCancelEditProfileMode', this.onRequestCancelEditProfileModeAction);

    GeneralStore.setProfileMode('Info');

    $('.tooltipped').tooltip({ delay: 50 });
    $('.modalPatientProfile').modal({
      dismissible: false,
    });
    $('.nucleus-modal-call').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
  }

  componentWillUnmount() {
    //Remove Listener
    CarePatientStore.removeListener('onPatientGetData', this.onPatientGetDataAction);
    CarePatientStore.removeListener('onGetPatientDevices', this.onGetPatientDevicesAction);
    CarePatientStore.removeListener('onUpdatePatientAction', this.onUpdatePatientAction);
    CarePatientStore.removeListener('onUnassignDevicePatientAction', this.onUnassignDevicePatientAction);
    CarePatientStore.removeListener('onOpenCallLogDetailsAction', this.onOpenCallLogDetailsAction);
    CarePatientStore.removeListener('onUpdateCallLogNotesAction', this.onUpdateCallLogNotesAction);

    CarePatientStore.removeListener('onGetCallRequestLogDetails2', this.onGetCallRequestLogDetails2Action);

    CarePatientStore.removeListener('onDeletePatientAction', this.onDeletePatientAction);
    GeneralStore.removeListener('onRequestCancelEditProfileMode', this.onRequestCancelEditProfileModeAction);
    $('.tooltipped').tooltip('remove');
  }

  onPatientGetDataAction(response) {
    console.log('onPatientGetDataAction', response);

    if (response.ok) {
      let age;
      if (response.DateOfBirth) {
        const today = new Date();
        const birthday = new Date(response.DateOfBirth);
        age = today.getFullYear() - birthday.getFullYear();
        const m = today.getMonth() - birthday.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
          age--;
        }
      } else {
        age = '-';
      }

      this.setState({
        patientID: response.PatientID,
        patientFirstName: response.FirstName,
        patientLastName: response.LastName,
        patientFullName: response.FirstName + ' ' + response.LastName,
        patientEmail: response.Email,
        patientBirthDate: response.DateOfBirth,
        patientTelephone: response.Telephone,
        patientAddress: response.Address,
        patientAge: age,
        patientZipCode: response.ZipCode,

        emergencyContactName: response.EmergencyContactName,
        emergencyContactTelephone: response.EmergencyContactTelephone,
        //deviceMacAddress : response.DeviceMac,

        showWeather: response.ShowWeather,
        deviceStatus: 'Online',
        ImageThumb: response.ImageThumb,

        //deviceInfo : response.DeviceInfo,
        //deviceOnline : response.DeviceInfo.Online,
        //deviceLastCheckIn : response.DeviceInfo.LastCheckinTime,
        //callLogs:response.logs
      });
    } else {
      Message.show('Error getting ' + AuthStore.getPatientLabel() + ' Information');
    }
  }

  onGetPatientDevicesAction(response) {
    if (response.ok) {
      let firstDeviceMacAddress = '';
      if (response.Devices.length > 0) {
        firstDeviceMacAddress = response.Devices[0].DeviceMac;

        if (response.Devices[0].DeviceMac == null || response.Devices[0].DeviceMac == '') {
          if (response.Devices[0].PreAssignedName != null) {
            firstDeviceMacAddress = response.Devices[0].PreAssignedName;
          } else {
            firstDeviceMacAddress = response.Devices[0].DeviceID;
          }
        }
      }
      this.setState({
        deviceMacAddress: firstDeviceMacAddress,
        devices: response.Devices,
      });
    }
  }

  onRequestCancelEditProfileModeAction() {
    this.handleProfileMode();
  }

  handleProfileMode() {
    if (this.state.currentMode == 'Info') {
      this.setState({
        currentMode: 'Edit',
        linkModeLabel: 'Cancel',
      });
      GeneralStore.setProfileMode('Edit');
    } else {
      this.setState({
        currentMode: 'Info',
        linkModeLabel: 'Edit',
      });
      GeneralStore.setProfileMode('Info');
    }
  }

  handleRemovePatientModal() {
    ($('#modalDeletePatient') as unknown as JQuery).modal('open');

    this.setState({
      removeBtnState: 'nucleus-submit-btn-small',
    });
  }

  handleCancelDelete() {
    ($('#modalDeletePatient') as unknown as JQuery).modal('close');
  }

  handleDeletePatient() {
    this.setState({
      removeBtnState: 'nucleus-submit-btn-small-disabled',
    });

    CarePatientStore.deletePatient({
      PatientID: this.state.patientID,
    });
  }

  onDeletePatientAction(response) {
    if (response.ok) {
      window.location.replace('/patients');
      CarePatientStore.getAllPatientsData({
        UserID: AuthStore.getUserID(),
      });
      setTimeout(function () {
        Message.show(AuthStore.getPatientLabel() + ' deleted successfully.');
      }, 700);
      ($('#modalDeletePatient') as unknown as JQuery).modal('close');
    } else {
      ($('#modalDeletePatient') as unknown as JQuery).modal('close');
      Message.show('Sorry, There was a problem deleting the ' + AuthStore.getPatientLabel() + '. Please try again.');
    }
  }

  onUpdatePatientAction(response) {
    console.log('onUpdatePatientAction', response);
    const { match } = this.props;

    if (response.ok) {
      this.setState({
        currentMode: 'Info',
        linkModeLabel: 'Edit',
      });
      Message.show(AuthStore.getPatientLabel() + ' successfully updated');
      //this.onUserGetDataAction(true);

      CarePatientStore.getPatientData({
        PatientID: match.params.patientId,
      });
    } else if (!response.telephoneRegistered) {
      Message.show('There was a problem updating the ' + AuthStore.getPatientLabel() + ' profile');
    }
  }

  onUnassignDevicePatientAction(response) {
    const { match } = this.props;
    console.log('onUnassignDevicePatientAction', response);
    console.log(response);

    if (response.ok) {
      /*CarePatientStore.getCareDevices({
                AccountID : AuthStore.getUserAccountID(),
            });

            this.setState({
                currentMode :  "Info",
                linkModeLabel : "Edit"
            });
            CarePatientStore.getPatientData({
                PatientID : match.params.patientId
            });
            */
      CarePatientStore.getPatientDevices({
        PatientID: match.params.patientId,
      });
      CarePatientStore.getCareDevices({
        AccountID: AuthStore.getUserAccountID(),
      });

      // Message.show("Deprecated 1 CarePatientStore.getPatientBiometricDevices");
      // CarePatientStore.getPatientBiometricDevices({
      //     PatientID : match.params.patientId
      // });
      //setTimeout(function(){ window.location.reload(); }, 1000);
      Message.show(AuthStore.getPatientLabel() + ' updated');
      //this.onUserGetDataAction(true);
    } else {
      Message.show('There was a problem updating the ' + AuthStore.getPatientLabel().toLowerCase() + ' profile');
    }
  }

  onOpenCallLogDetailsAction(callLog) {
    console.log('onOpenCallLogDetailsAction');
    console.log(callLog);

    let isAutor = true;
    if (callLog.NotesUserID && callLog.NotesUserID.toUpperCase() != AuthStore.getUserID().toUpperCase()) {
      isAutor = false;
    }
    //console.log("Is Autor", isAutor);

    if (callLog.ok) {
      this.setState({
        callLogID: callLog.LogID,
        callLogStartTime: callLog.LogStartTime,
        callLogStatus: callLog.LogStatus,
        callLogDuration: callLog.LogDuration != '' ? callLog.LogDuration : '-',
        callLogParticipants: callLog.LogParticipants,
        callLogRequest: callLog.LogRequest,
        callLogNotes: callLog.Notes,

        callLogModalBtnState: isAutor ? 'nucleus-submit-btn' : 'nucleus-submit-btn-disabled',
        callLogModalTxtState: isAutor ? '' : 'disabled',
      });

      this.refs.textCallLogNotes.value = callLog.Notes || '';
      ($('#modal1') as unknown as JQuery).modal('open');
    } else {
      Message.show('There was a problem getting the call log notes');
    }
  }

  handleCloseModal() {
    ($('#modal1') as unknown as JQuery).modal('close');
  }

  handleOpenParticipantStats(callParticipantLogID) {
    console.log('handleOpenParticipantStats', callParticipantLogID);
    const URL = Config.rootDomain + '/ws/ShowVideoCallStatsForParticipant.aspx?PLogID=' + callParticipantLogID;
    window.open(URL, '_blank');
  }

  handleSaveNotes() {
    console.log(this.state.callLogID);

    CarePatientStore.updateCallLogNotes({
      CallLogID: this.state.callLogID,
      Notes: this.refs.textCallLogNotes.value,
      UserID: AuthStore.getUserID(),
    });
  }

  onUpdateCallLogNotesAction(response) {
    if (response.ok) {
      ($('#modal1') as unknown as JQuery).modal('close');

      CarePatientStore.refreshPatientCallLogs();

      // CarePatientStore.getPatientCallLogs({
      //     PatientID : match.params.patientId
      // });

      //window.location.reload();
      //Message.show("Call Log updated");
    } else {
      ($('#modal1') as unknown as JQuery).modal('close');
      Message.show('There was a problem updating the call log note');
    }
  }

  onGetCallRequestLogDetails2Action() {}

  copyPatientIdToClipboard() {
    const { match } = this.props;
    const patientID = match.params.patientId;

    const copyText = document.createTextNode(patientID);
    const textArea = document.createElement('textarea');

    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    Message.show('Patien ID copied to clipboard');
  }

  render() {
    console.log('RENDER PatientProfile->');
    const { match } = this.props;

    let deviceMacAddress = 'No Device assigned';
    let deviceStatus = '';
    if (this.state.deviceMacAddress != '' && this.state.deviceMacAddress != null) {
      deviceMacAddress = this.state.deviceMacAddress;
      deviceStatus = 'Online';
    }
    const infoVisibility = this.state.currentMode == 'Info' ? ' ' : ' hide';
    const editVisibility = this.state.currentMode == 'Info' ? ' hide' : ' ';

    const callLogDetailsParticipants = [];
    if (this.state.callLogParticipants != null && this.state.callLogParticipants.length > 0) {
      this.state.callLogParticipants.map((participantData, key) => {
        if (participantData.HasVideoStats) {
          callLogDetailsParticipants.push(
            <div className="no-margin a-bit-lower" key={key}>
              <a className="btn-floating btn-sm waves-effect waves-light nucleus-floating-btn-mini a-left" onClick={this.handleOpenParticipantStats.bind(this, participantData.ID)}>
                <i className="material-icons nucleus-floating-btn-icon-mini">save_alt</i>
              </a>
              &nbsp; <span className="nucleus-labels nucleus-font-label-body">{participantData.Description}</span>
            </div>,
          );
        } else {
          callLogDetailsParticipants.push(
            <div className="no-margin a-bit-lower" key={key}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span className="nucleus-labels nucleus-font-label-body">{participantData.Description}</span>
            </div>,
          );
        }
      });
    }

    let callDetailsColumn1 = 'col s8';
    let callDetailsColumn2 = 'col s4';

    if (this.state.callLogRequest != null && this.state.callLogRequest.ID) {
      callDetailsColumn1 = 'col s6';
      callDetailsColumn2 = 'col s6';
    }

    //console.log("DEVICE STATUS RENDER: ", this.state.deviceOnline, this.state.deviceLastCheckIn);
    console.log('DEVICE STATUS RENDER: ');
    console.log('match =====>', match);

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <div className={'nucleus-page-title toper-container ' + infoVisibility}>
            <div className="row no-margin">
              <div className="col s1"></div>
              <div className="col s7">
                <span className="new-page-title" style={{ paddingLeft: 3 }}>
                  {this.state.patientFullName}
                  &nbsp;&nbsp;
                </span>
                <a
                  className="btn-floating btn waves-effect waves-light dark-blue nucleus-floating-btn-big a-left tooltipped"
                  data-position="top"
                  data-delay="50"
                  data-tooltip="Copy Patient ID for API"
                  onClick={this.copyPatientIdToClipboard}
                >
                  <i className="material-icons nucleus-floating-btn-icon-copy-to-cb">file_copy</i>
                </a>
              </div>
              <div className="col s4 right-align">
                <a id={'edit-patient'} className="nucleus-font-small nucleus-link profile-header-btn1" onClick={this.handleProfileMode}>
                  {this.state.linkModeLabel}
                </a>
                &nbsp;&nbsp;&nbsp;
                {AuthStore.canDeletePatients() && (
                  <a id={'remove-patient'} className="nucleus-font-small nucleus-link profile-header-btn2" onClick={this.handleRemovePatientModal}>
                    <span className="txt-red">Remove</span>
                  </a>
                )}
              </div>
            </div>
          </div>
          <p className={'nucleus-page-title ' + editVisibility}>
            {this.state.patientFullName}
            &nbsp;&nbsp;&nbsp;
            <a className="nucleus-font-small  nucleus-link" onClick={this.handleProfileMode}>
              {' '}
              {this.state.linkModeLabel}
            </a>
          </p>

          <div className="altoImportantContainer">
            <PatientProfileInfo
              patientImageThumb={this.state.ImageThumb}
              currentMode={this.state.currentMode}
              patientId={match.params.patientId}
              patientFullName={this.state.patientFullName}
              patientFirstName={this.state.patientFirstName}
              patientLastName={this.state.patientLastName}
              patientEmail={this.state.patientEmail}
              patientTelephone={this.state.patientTelephone}
              patientAddress={this.state.patientAddress}
              patientDateOfBirth={this.state.patientBirthDate}
              patientAge={this.state.patientAge}
              patientZipCode={this.state.patientZipCode}
              patientContactName={this.state.emergencyContactName}
              patientContactTelephone={this.state.emergencyContactTelephone}
              patientDeviceMac={deviceMacAddress}
              patientDeviceStatus={deviceStatus}
              patientDevices={this.state.devices}
              ////patientDeviceOnline={this.state.deviceOnline}
              ////patientDeviceLastOnline={this.state.deviceLastCheckIn}

              //patientCallLogs={this.state.callLogs}
              patientPendingRequest={this.state.pendingRequest}
              autoCallDeviceID={this.autoCallDeviceID}
              autoCallMode={this.autoCallMode}
              currentUserAdmin={this.state.userAdmin}
            />

            <PatientProfileEdit
              patientImageThumb={this.state.ImageThumb}
              currentMode={this.state.currentMode}
              patientId={this.state.patientID}
              patientFirstName={this.state.patientFirstName}
              patientLastName={this.state.patientLastName}
              patientEmail={this.state.patientEmail}
              patientTelephone={this.state.patientTelephone || ''}
              patientAddress={this.state.patientAddress}
              patientZipCode={this.state.patientZipCode}
              patientDateOfBirth={this.state.patientBirthDate}
              patientContactName={this.state.emergencyContactName}
              patientContactTelephone={this.state.emergencyContactTelephone}
              patientDeviceMac={deviceMacAddress}
              patientDeviceStatus={deviceStatus}
              patientShowWeather={this.state.showWeather}
              //patientCallLogs={this.state.callLogs}
            />

            <div id="modal1" className="modal modalPatientProfile modalScrollableWidth">
              <div className="modal-content nucleus-modal-content-no-bottom-padding txt-special-color nucleus-modal-wrapper2">
                <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseModal} />
                <h3 className="nucleus-page-subtitle">&nbsp; Call Details</h3>
                <br />
                <br />
                <div className="row no-margin">
                  <div className={callDetailsColumn1 + ' left-align '}>
                    <div className="row bottom-separated">
                      <span className="nucleus-font-label-title">Date & Time:</span>
                      <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.callLogStartTime}</span>
                    </div>
                    <div className="row bottom-separated">
                      <span className="nucleus-font-label-title">Status:</span>
                      <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.callLogStatus}</span>
                    </div>
                    <div className="row bottom-separated">
                      <span className="nucleus-font-label-title">Duration:</span>{' '}
                      <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.callLogDuration}</span>
                    </div>
                  </div>
                  <div className={callDetailsColumn2 + ' left-align '}>
                    {this.state.callLogRequest != null && this.state.callLogRequest.ID ? (
                      <div className="row no-margin">
                        <div className="row bottom-separated">
                          <span className="nucleus-font-label-title">First Requested:</span>
                          <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.callLogRequest.Time}</span>
                        </div>

                        <div className="row bottom-separated">
                          <span className="nucleus-font-label-title">Request Type:</span>
                          <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.callLogRequest.Type}</span>
                        </div>
                        <div className="row bottom-separated">
                          <span className="nucleus-font-label-title">Escalated:</span>
                          <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.callLogRequest.Escalated}</span>
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>

                <div className="row no-margin">
                  <div className="row s4 m3 l3 xl2 left-align a-little-right-11">
                    <span className="nucleus-font-label-title">
                      Participants: <br />
                    </span>
                  </div>
                  <div className="row s8 m9 l9 xl10 left-align a-bit-upper a-little-right-11">{callLogDetailsParticipants}</div>
                </div>
                <br />
                <div className="input-field a-few-upper col s12" key={this.state.callLogNotes}>
                  <div className="row bottom-separated">
                    <span className="nucleus-font-label-title">Call notes:</span> <br />
                  </div>
                  <textarea
                    ref="textCallLogNotes"
                    className="nucleus-textarea "
                    defaultValue={this.state.callLogNotes}
                    placeholder="Enter note"
                    disabled={this.state.callLogModalTxtState}
                  ></textarea>
                  <br />
                  <div className="row no-margin">
                    <div className="col s12 center-align no-margin">
                      <p></p>
                      <span className=" modalSeparator">
                        {' '}
                        <label> &nbsp;</label>
                      </span>
                      {/*
                                        <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCloseModal}>Cancel</a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        */}
                      <br />
                      <a className={'txt-white nucleus-font-modal-btn ' + this.state.callLogModalBtnState} onClick={this.handleSaveNotes}>
                        Save
                      </a>
                      <div>&nbsp;</div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="modalDeletePatient" className="modal modalPatientProfile modalDeletePatient">
              <div className="modal-content modal-content-delete">
                <h3 className="nucleus-page-subtitle"> Delete {AuthStore.getPatientLabel()}</h3>
                <p></p>
                <div className="row">
                  <div className="col s12 left-align nucleus-font-medium">
                    Are you sure you want to remove this {AuthStore.getPatientLabel().toLowerCase()}?
                    <br /> <br /> <br />
                  </div>
                </div>
                <div className="row ">
                  <div className="col s12 right-align ">
                    <p></p>
                    <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelDelete}>
                      Cancel
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className={'txt-white nucleus-font-small ' + this.state.removeBtnState} onClick={this.handleDeletePatient}>
                      Confirm
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PatientProfileFC = props => {
  return useReactRouterProps(PatientProfile, props);
};

export default PatientProfileFC;
