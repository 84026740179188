import { useReactRouterProps } from 'hooks/useReactRouterProps';
import moment from 'moment';
import React from 'react';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { JQuery } from 'types/jquery.types';
import MdmDeviceApi from '../../../apis/MdmDeviceApi';
import CarePatientStore from '../../../stores/CarePatientStore';
import Message from '../../../utils/Message';

class CarePatientDeviceItemRow extends React.Component<
  {
    ID: string;
    Name: string;
    Mac: string;
    Online: boolean;
    LastOnline: string;
    TimezoneOffset: number;
    Battery: number;
    Version: string;
    HexnodeID: string;
    OS: string;
    AutoAnswerAllowed: boolean;
    UserBargeInEnabled: boolean;
    restartDeviceAccess: boolean;
    MdmID: string;
    DeviceInfo: string;
    currentUserAdmin: boolean;
    index: number;
    Volume: number;
    Concierge: boolean;
    Label: string;
    MdmDeviceID: string;
    TimeZone: string;
  },
  {
    DeviceID: string;
    volumeBarStyle: string;
    deviceVolume: number;
    deviceConcierge: boolean;
    patientPendingRequestIcon?: string;
  }
> {
  private MdmDeviceApi: MdmDeviceApi;
  private isConnectDevice: boolean;

  constructor(props) {
    super(props);
    this.MdmDeviceApi = new MdmDeviceApi(this);
    this.state = {
      DeviceID: this.props.ID,
      volumeBarStyle: 'deviceVolume',
      deviceVolume: 0,
      deviceConcierge: false,
    };

    this.handleRowClicked = this.handleRowClicked.bind(this);

    this.handleAudioCall = this.handleAudioCall.bind(this);
    this.handleVideoCall = this.handleVideoCall.bind(this);

    this.handleBargeCall = this.handleBargeCall.bind(this);
    this.handleSilentBargeCall = this.handleSilentBargeCall.bind(this);

    this.handleUnassignDevice = this.handleUnassignDevice.bind(this);

    this.onDeviceVolumeUpdatedAction = this.onDeviceVolumeUpdatedAction.bind(this);

    this.handleCopyDeviceID = this.handleCopyDeviceID.bind(this);
    this.requestRestart = this.requestRestart.bind(this);

    this.isConnectDevice = true;
  }

  componentDidMount() {
    CarePatientStore.on('onSelectedDeviceVolumeUpdated', this.onDeviceVolumeUpdatedAction);

    ($('.tooltipped') as unknown as JQuery).tooltip({ delay: 50 });

    this.setState({
      deviceVolume: this.props.Volume,
      deviceConcierge: this.props.Concierge,
    });

    if (this.props.DeviceInfo && this.props.DeviceInfo != null) {
      const device_Info = JSON.parse(this.props.DeviceInfo);

      if (device_Info && device_Info.brand != null && device_Info.brand == 'goldtek') {
        this.isConnectDevice = false;
        console.log('The info', device_Info.brand);
      }
    }
  }

  componentWillUnmount() {
    CarePatientStore.removeListener('onSelectedDeviceVolumeUpdated', this.onDeviceVolumeUpdatedAction);
  }

  handleRowClicked() {}

  handleAudioCall() {
    console.log('handleAudioCall ', this.props.ID);
    CarePatientStore.handlePatientProfileInfoAudioCall({
      Devices: [
        {
          id: this.props.ID,
          name: this.props.Name,
        },
      ],
      ConnectDevice: this.isConnectDevice,
    });
  }

  handleVideoCall() {
    console.log('handleVideoCall ', this.props.ID);

    CarePatientStore.handlePatientProfileInfoVideoCall({
      Devices: [
        {
          id: this.props.ID,
          name: this.props.Name,
        },
      ],
      ConnectDevice: this.isConnectDevice,
    });
  }

  handleBargeCall() {
    console.log('handleBargeCall ', this.props.ID);

    CarePatientStore.handlePatientProfileInfoBargeCall({
      Devices: [
        {
          id: this.props.ID,
          name: this.props.Name,
        },
      ],
      ConnectDevice: this.isConnectDevice,
    });
  }

  handleSilentBargeCall() {
    console.log('handleSilentBargeCall ', this.props.ID);

    CarePatientStore.handlePatientProfileInfoSilentBargeCall({
      Devices: [
        {
          id: this.props.ID,
          name: this.props.Name,
        },
      ],
      ConnectDevice: this.isConnectDevice,
    });
  }

  handleUnassignDevice() {
    console.log('handleUnassignDevice ', this.props);
    CarePatientStore.handlePatientProfileInfoUnassignDevice({
      DeviceID: this.props.ID,
      DeviceMac: this.props.Mac,
      DeviceName: this.props.Name,
      HexnodeID: this.props.HexnodeID,
      DeviceLabel: this.props.Label,
      DeviceVolume: this.state.deviceVolume,
      DeviceConcierge: this.state.deviceConcierge,
      DeviceOS: this.props.OS,
      IsLegacyDevice: !this.isConnectDevice,
    });
  }

  onDeviceVolumeUpdatedAction(response) {
    console.log('onDeviceVolumeUpdatedAction');
    if (response.deviceID == this.props.ID) {
      if (response.ok) {
        this.setState({
          deviceVolume: response.volume,
        });
      }
    }
  }

  handleCopyDeviceID() {
    console.log('handleCopyDeviceID');

    const deviceID = this.props.ID;

    const copyText = document.createTextNode(deviceID);
    const textArea = document.createElement('textarea');

    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();

    console.log('handleCopyDeviceID', deviceID);

    Message.show('Device ' + this.props.Name + ' ID has been copied!');
  }

  async requestRestart() {
    console.log('requestRestart Mac', this.props.Mac);
    console.log('requestRestart HexnodeID', this.props.HexnodeID);
    if (this.props.HexnodeID) {
      CarePatientStore.launchRequestRestart({
        DeviceID: this.props.ID,
        DeviceMac: this.props.Mac,
        HexnodeID: this.props.HexnodeID,
      });
    } else {
      console.log('Request device reboot', this.props.MdmDeviceID, this.props.ID);
      try {
        const response = await this.MdmDeviceApi.requestDeviceReboot({
          MdmID: this.props.MdmID,
        });
        if (response) {
          Message.show('Reboot request sent');
        }
      } catch (error) {
        Message.show('Failed to send reboot request');
      }
    }
  }

  render() {
    //console.log("DEVICE STATUS2: ", this.props.patientDevices);
    let deviceName = this.props.Name;
    if (deviceName != null && deviceName.length > 22) {
      deviceName = deviceName.substring(0, 22) + ' ...';
    }

    let deviceOnlineVisibility = '';
    let deviceOfflineVisibility = 'hide';
    let lastOnline = this.props.LastOnline;

    if (!this.props.Online) {
      deviceOnlineVisibility = 'hide';
      deviceOfflineVisibility = '';

      lastOnline = 'Device not connected    ';
    }
    if (this.props.LastOnline && this.props.LastOnline.length > 0) {
      const _date = new Date(this.props.LastOnline);
      _date.setHours(_date.getHours() + this.props.TimezoneOffset);
      lastOnline = moment(_date).format('MM/DD/YYYY hh:mm A');
    }

    lastOnline += ' - ' + this.props.TimeZone;

    const canSeeUnassign = this.props.currentUserAdmin ? ' ' : ' hide';

    const ver = this.props.Version && this.props.Version.length > 0 ? this.props.Version : '--';

    const autoAnswerAllowedStyle = this.props.AutoAnswerAllowed && this.props.UserBargeInEnabled ? ' ' : ' hide';

    return (
      <tr id={`device-${this.props.index}`} className="">
        <td className="nucleus-row-small">
          <a
            className="btn-floating btn waves-effect waves-light dark-blue nucleus-floating-btn a-left"
            onClick={this.handleCopyDeviceID}
            data-tooltip-id="copy_device_id"
            data-tooltip-content={'Copy DeviceID'}
          >
            <i className="material-icons nucleus-floating-btn-icon-copy-to-cb">file_copy</i>
            <ReactTooltip id="copy_device_id" place="top" variant="dark" float={true} delayShow={50} />
          </a>
        </td>
        <td className="nucleus-row-small">{deviceName}</td>
        <td className="nucleus-row-small">
          <div className="row no-margin a-bit-lower3">
            <div className="col s11 m11 no-margin">{lastOnline}</div>
            <div className="col s1 m1 no-margin">
              <span className={deviceOnlineVisibility}>
                <img src="/img/icon_device_active.png" className="tooltipped a-little-bit-lower" data-position="top" data-delay="50" data-tooltip="Online" />
              </span>
              <span className={deviceOfflineVisibility}>
                <img src="/img/icon_device_inactive.png" className="tooltipped a-little-bit-lower" data-position="top" data-delay="50" data-tooltip="Offline" />
              </span>
            </div>
          </div>
        </td>
        <td
          className="nucleus-row-small center-align"
          style={{
            color: this.props.Battery < 50 ? 'red' : 'black',
          }}
        >
          {this.props.Battery ? this.props.Battery + '%' : ''}
        </td>
        <td className="nucleus-row-small center-align">{ver}</td>
        <td className="nucleus-row-small center-align ">
          <p className="device-icons-line no-margin">
            <img
              id={`handle-videocall-device-${this.props.index}`}
              src="/img/icon_videocall_big.png"
              className="nucleus-link tooltipped"
              data-position="top"
              data-delay="10"
              data-tooltip="Video Call"
              onClick={this.handleVideoCall}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <img
              id={`handle-audiocall-device-${this.props.index}`}
              src="/img/icon_audiocall_big.png"
              className="nucleus-link tooltipped"
              data-position="top"
              data-delay="10"
              data-tooltip="Audio Call"
              onClick={this.handleAudioCall}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className={'no-margin ' + autoAnswerAllowedStyle}>
              <img
                id={`handle-autoallowedcall-device-${this.props.index}`}
                src="/img/icon_new_instant_big.png"
                className="nucleus-link tooltipped"
                data-position="top"
                data-delay="10"
                data-tooltip="Auto Answer"
                onClick={this.handleBargeCall}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <img
                id={`handle-silent-autocall-device-${this.props.index}`}
                src="/img/icon_new_silent_instant_big.png"
                className="nucleus-link tooltipped"
                data-position="top"
                data-delay="10"
                data-tooltip="Silent Auto Answer"
                onClick={this.handleSilentBargeCall}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            {this.props.restartDeviceAccess && (
              <img
                id={`handle-restart-device-${this.props.index}`}
                src="/img/icon_device_restart.png"
                className="nucleus-link tooltipped"
                data-position="top"
                data-delay="10"
                data-tooltip="Restart"
                onClick={this.requestRestart}
              />
            )}
            <span className={'chart-dark-white ' + this.state.patientPendingRequestIcon}> &bull;</span>
          </p>
        </td>

        <td className="nucleus-row-small left-align ">
          <a
            id={`edit-device-${this.props.index}`}
            className={'nucleus-link nucleus-font-14 row-action tooltipped ' + canSeeUnassign}
            data-position="top"
            data-delay="10"
            data-tooltip="Open device details"
            onClick={this.handleUnassignDevice}
          >
            Edit &nbsp;&nbsp;
          </a>
        </td>
      </tr>
    );
  }
}

export default props => useReactRouterProps(CarePatientDeviceItemRow, props);
