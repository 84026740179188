import React from 'react';

const UserGuides = () => {
  return (
    <div style={{}}>
      <h3 className="nucleus-page-subtitle">User Guides</h3>
      <div style={{ marginTop: 20 }}>
        {/* 1 */}
        <div
          className="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/How_To_Home_Logo_Resize_2024.pdf'}
            title="How to Navigate the Home Tab on the Client Portal"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-12.32-1-1.png"
          />
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/How_To_Password_Logo_Resize_2024.pdf'}
            title="How to Change Your Password on the Client Portal"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-11.03-2-1.png"
          />
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/How_to_Reports_Logo_Resize_2024.pdf'}
            title="How to Navigate the Reports Tab on the Client Portal"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-12.32-2-1.png"
          />
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/How_to_Photos_Portal_v02_2024.pdf'}
            title="How to Navigate the Photos Tab on the Client Portal"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-11.01-3-1.png"
          />
        </div>

        {/* 2 */}
        <div
          className="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/How_to_Staff_Logo_Resize_2024.pdf'}
            title="How to Navigate the Staff Tab on the Client Portal"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-12.36-1-1.png"
          />
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/How_to_Queue_Logo_Resize_2024.pdf'}
            title="How to Navigate the Queue Tab on the Client Portal"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-12.33-1-1.png"
          />
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/How_To_Alerts_Logo_Resize_2024.pdf'}
            title="How to Navigate the Alerts Tab on the Client Portal"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-12.32-3-1.png"
          />
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/how.to.messages_v02_2024.pdf'}
            title="How to Navigate the Messages Tab on the Client Portal"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-11.03-1-1.png"
          />
        </div>

        {/* 3 */}
        <div
          className="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/how.to.classes_v02_2024.pdf'}
            title="How to Navigate the Classes Tab on the Client Portal"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-11.04-2-1.png"
          />
          <GuideContainer
            target={'https://nucleuscare.com/wp-content/uploads/2022/01/HowTo.WiFi_.pdf'}
            title="How to Connect to WiFi From a Seness Device"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-11.00-1-1.png"
          />
          <GuideContainer
            target={'https://nucleuscare.com/wp-content/uploads/2022/01/HowTo.Photos.ios_.pdf'}
            title="How to Add Photos to a Seness With Your iOS Smartphone"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-11.02-1-1.png"
          />
          <GuideContainer
            target={'https://nucleuscare.com/wp-content/uploads/2022/01/HowTo.Photos.Android.pdf'}
            title="How to Add Photos to a Seness With Your Android Smartphone"
            imageSrc="https://nucleuscare.com/wp-content/uploads/2022/01/Screen-Shot-2022-01-11-at-12.33-2-1.png"
          />
          {/* <GuideContainer target={""} title="" imageSrc="" /> */}
        </div>

        <div
          className="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/How_To_Clients_v02_2024.pdf'}
            title="How to Navigate the Clients Tab on the Client Portal"
            imageSrc="https://nucleuscare-data.s3.us-west-2.amazonaws.com/images/Navigate_Clients_Tab.png"
          />
          <GuideContainer
            target={'https://nucleuscare-data.s3.us-west-2.amazonaws.com/guides/How_To_Photos_Logo_Resize_2024.pdf'}
            title="How to Add Photos to a Nucleus Device With Your Smart Phone"
            imageSrc="https://nucleuscare-data.s3.us-west-2.amazonaws.com/images/Add_Photos.png"
          />
          <GuideContainer target={''} title="" imageSrc="" />
          <GuideContainer target={''} title="" imageSrc="" />
          {/* <GuideContainer target={""} title="" imageSrc="" /> */}
        </div>
      </div>
      <div className="nucleus-news-separator">&nbsp;</div>
    </div>
  );
};
const GuideContainer = ({ imageSrc, title, target }) => {
  return (
    <div
      style={{ cursor: 'pointer', flex: 1 }}
      onClick={() => {
        if (target != null) return window.open(target);
        else return console.log('No Target Found');
      }}
      className="col-3 text-center-align"
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img style={{ width: 160, alignSelf: 'center' }} src={imageSrc}></img>
        <div
          style={{
            width: '50%',
            justifyContent: 'center',
            alignSelf: 'center',
          }}
        >
          <p style={{ textAlign: 'center' }}>{title}</p>
        </div>
      </div>
    </div>
  );
};
export default UserGuides;
