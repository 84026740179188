import classNames from 'classnames';
import React from 'react';
import { JQuery } from 'types/jquery.types';
import UserStore from '../../stores/UserStore';
import Message from '../../utils/Message';
//import _ from "underscore";

class SettingsUser extends React.Component<
  {
    userAccountID: string;
    userIsAdmin: boolean;
    companyShowCallButton: boolean;
    companyShowCheckInButton: boolean;
    companyShowEmergencyButton: boolean;
    userImageUrl: string;
    userFullName: string;
    userEmail: string;
    userTelephone: string;
    userAddress: string;
    userIsOnDuty: boolean;
    userAllPatientsAccess: boolean;
    currentMode: string;
  },
  {
    userOnDuty?: boolean;
    userAllPatientsAccess?: boolean;
  }
> {
  imgRef: HTMLImageElement;

  constructor(props) {
    super(props);
    this.state = {};

    this.handleShowCallBtn = this.handleShowCallBtn.bind(this);
    this.handleShowCheckInBtn = this.handleShowCheckInBtn.bind(this);
    this.handleShowEmergencyBtn = this.handleShowEmergencyBtn.bind(this);

    this.onSetShowCallBtnAction = this.onSetShowCallBtnAction.bind(this);
    this.onSetShowCheckInBtnAction = this.onSetShowCheckInBtnAction.bind(this);
    this.onSetShowEmergencyBtnAction = this.onSetShowEmergencyBtnAction.bind(this);

    this.handleOnDutyProfile = this.handleOnDutyProfile.bind(this);
    this.onSetUserOnDutyAction = this.onSetUserOnDutyAction.bind(this);

    this.handleAllPatientsProfile = this.handleAllPatientsProfile.bind(this);
    this.onSetSeeAllPatientsAction = this.onSetSeeAllPatientsAction.bind(this);
  }

  componentDidMount() {
    UserStore.on('onSetShowCallBtn', this.onSetShowCallBtnAction);
    UserStore.on('onSetShowCheckInBtn', this.onSetShowCheckInBtnAction);
    UserStore.on('onSetShowEmergencyBtn', this.onSetShowEmergencyBtnAction);

    UserStore.on('onSetUserOnDuty', this.onSetUserOnDutyAction);
    UserStore.on('onSetSeeAllPatients', this.onSetSeeAllPatientsAction);

    ($('.tooltipped') as unknown as JQuery).tooltip({ delay: 50 });
  }

  componentWillUnmount() {
    UserStore.removeListener('onSetShowCallBtn', this.onSetShowCallBtnAction);
    UserStore.removeListener('onSetShowCheckInBtn', this.onSetShowCheckInBtnAction);
    UserStore.removeListener('onSetShowEmergencyBtn', this.onSetShowEmergencyBtnAction);

    UserStore.removeListener('onSetUserOnDuty', this.onSetUserOnDutyAction);
    UserStore.removeListener('onSetSeeAllPatients', this.onSetSeeAllPatientsAction);
    ($('.tooltipped') as unknown as JQuery).tooltip('remove');
  }

  // Deprecated
  // Removed Oct 5 - CARE-1157
  handleShowCallBtn(e) {
    const show = e.target.checked;
    console.log('handleShowCallBtn ', show);

    UserStore.setShowCallBtn({
      AccountID: this.props.userAccountID,
      Show: show,
    });
  }
  onSetShowCallBtnAction(response) {
    if (response.ok) {
      if (response.show) {
        Message.show('Button Call will appear');
      } else {
        Message.show('Button Call will disappear');
      }
    } else {
      Message.show('Error updating Show Call button state, please try again.');
    }
  }

  // Deprecated
  // Removed Oct 5 - CARE-1157
  handleShowCheckInBtn(e) {
    const show = e.target.checked;
    console.log('handleShowCheckInBtn ', show);

    UserStore.setShowCheckInBtn({
      AccountID: this.props.userAccountID,
      Show: show,
    });
  }
  onSetShowCheckInBtnAction(response) {
    if (response.ok) {
      if (response.show) {
        Message.show('Button CheckIn will appear');
      } else {
        Message.show('Button CheckIn will disappear');
      }
    } else {
      Message.show('Error updating Show CheckIn button state, please try again.');
    }
  }

  // Deprecated
  // Removed Oct 5 - CARE-1157
  handleShowEmergencyBtn(e) {
    const show = e.target.checked;
    console.log('handleShowEmergencyBtn ', show);

    UserStore.setShowEmergencyBtn({
      AccountID: this.props.userAccountID,
      Show: show,
    });
  }
  onSetShowEmergencyBtnAction(response) {
    if (response.ok) {
      if (response.show) {
        Message.show('Button Emergency Call will appear');
      } else {
        Message.show('Button Emergency Call will disappear');
      }
    } else {
      Message.show('Error updating Show Emergency Call button state, please try again.');
    }
  }

  handleOnDutyProfile(e) {
    console.log('Checkbox');
    //console.log(e);
    const onDuty = e.target.checked;
    console.log(onDuty);

    //Get the User Data from WS
    UserStore.setUserOnDuty({
      UserID: UserStore.getID(),
      IsOnDuty: onDuty,
    });
  }

  onSetUserOnDutyAction(result) {
    console.log('onSetUserOnDutyAction');
    console.log(result);
    //console.log(this.state.userID);

    //if (result.userId == this.props.userId) {
    if (result.ok) {
      // this.setState({
      //     userOnDuty : result.onDuty
      // });
      if (result.onDuty) {
        Message.show('You are On Duty now');
      } else {
        Message.show('You are Off now');
      }
    } else {
      Message.show('Error updating your OnDuty, please try again.');
    }
    //}
  }

  //Deprecated: Removed on Sep 22 - CARE-993
  handleAllPatientsProfile(e) {
    console.log('Checkbox');
    //console.log(e);
    const seeAllPatients = e.target.checked;
    console.log(seeAllPatients);

    //Get the User Data from WS
    UserStore.setSeeAllPatients({
      UserID: UserStore.getID(),
      CanSee: seeAllPatients,
    });
  }
  onSetSeeAllPatientsAction(result) {
    console.log('onSetSeeAllPatientsAction');
    console.log(result);
    if (result.ok) {
      if (result.canSee) {
        Message.show('You can see all the patients now');
      } else {
        Message.show('You will see only your assigned patients');
      }
    } else {
      Message.show('Error updating your Profile, please try again.');
    }
  }

  render() {
    const currentSection = classNames({
      hide: this.props.currentMode != 'Info',
    });

    //let onDutyValue = this.props.userIsOnDuty ? "checked" : "";
    //let allPatientsValue = this.props.userAllPatientsAccess ? "checked" : "";

    return (
      <div className={'nucleus-user-pending ' + currentSection}>
        <div className="row valign-wrapper">
          <div className="col s4 m3 l2 ">
            <img
              className=" user-img-settings"
              style={{ objectFit: 'cover' }}
              src={this.props.userImageUrl}
              ref={img => (this.imgRef = img)}
              onError={() => (this.imgRef.src = '/img/user_placeholder.png')}
            />
          </div>
          <div className="col s4 m3 l3">
            <span className=" nucleus-labels-height"> {this.props.userFullName}</span>
            <label>&nbsp;</label>
            <br />
            <span className=" nucleus-labels-height"> {this.props.userEmail}</span>
            <label>&nbsp;</label>
            <br />
            <span className=" nucleus-labels-height"> {this.props.userTelephone}</span>
          </div>
          <div className="col s3 m3 l3">
            <span className=" nucleus-labels-height"> {this.props.userAddress}</span>
            <label>&nbsp;</label>
          </div>
          <div className="col s1 m3 l4">
            {/*
                        // Removed Sep 25 - CARE-1022
                        <span className="nucleus-labels-bold"> <b>&nbsp;&nbsp;On Duty</b> </span> 
                        <div className="switch">
                            <label key={onDutyValue}>
                              <input type="checkbox" defaultChecked={onDutyValue} onChange={this.handleOnDutyProfile}/>
                              <span className="lever"></span>
                            </label>
                        </div>
                        */}

            {/*
                        // Removed Sep 22 - CARE-993
                        <span className="nucleus-labels-bold tooltipped" data-position="right" data-delay="50" data-tooltip="Feature available temporarily"> <b>All { AuthStore.getPatientLabelPlural() } access *</b> </span> 
                        <div className="switch">
                            <label key={allPatientsValue}>
                              <input type="checkbox" defaultChecked={allPatientsValue} onChange={this.handleAllPatientsProfile}/>
                              <span className="lever"></span>
                            </label>
                        </div>
                        */}
          </div>
        </div>
        <div className="nucleus-news-separator">&nbsp;</div>
        {/*
                // Removed Oct 5 - CARE-1157
                <div class={" " + showConfigAccount } >
                    <p className="nucleus-page-title">Care Account Settings</p>
                    <div className="row valign-wrapper">
                        <div className="col s6 m4 l4 no-margin">
                            <span className=" nucleus-labels"> Show CheckIn button</span> 
                            <br/><br/>
                            <span className=" nucleus-labels"> Show Emergency Calls button</span>
                            <br/><br/>
                            <span className=" nucleus-labels"> Show Call Care Company button</span>
                        </div>
                        <div className="col s6 m6 l6">
                            
                            <div className="switch">
                                <label key={showCheckInBtnValue}>
                                  <input type="checkbox" defaultChecked={showCheckInBtnValue} onChange={this.handleShowCheckInBtn}/>
                                  <span className="lever"></span>
                                </label>
                            </div>
                            <br/>
                            
                            <div className="switch">
                                <label key={showEmergencyBtnValue}>
                                  <input type="checkbox" defaultChecked={showEmergencyBtnValue} onChange={this.handleShowEmergencyBtn}/>
                                  <span className="lever"></span>
                                </label>
                            </div>
                            <br/>

                            <div className="switch">
                                <label key={showCallBtnValue}>
                                  <input type="checkbox" defaultChecked={showCallBtnValue} onChange={this.handleShowCallBtn}/>
                                  <span className="lever"></span>
                                </label>
                            </div>
                            
                        </div>
                        
                        <div className="col s0 m2 l2 center-align">
                            
                        </div>
                    </div>
                </div>
                */}
      </div>
    );
  }
}

export default SettingsUser;
