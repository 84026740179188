import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import Config from '../Config';
import NucleusNetwork from '../utils/NucleusNetwork';

class MessagesApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<-------------------------Get Messages for User--------------------------->
  GetMessagesForUser(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetMessagesForUser',
      data: data,
      callback: callback,
    });
  }

  GetMessagesForAccount(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetMessagesByDateTimeForAccount',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------Get Message Categories--------------------------->
  GetMessageCategories(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetMessageCategories',
      data: data,
      callback: callback,
    });
  }

  GetMessageTemplates(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetMessageSingleOptionTemplates',
      data: data,
      callback: callback,
    });
  }

  GetBiometricsMessagesTemplates(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetBiometricMessagesTypes',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------Get Message Categories--------------------------->
  GetAutocompletePatientsList(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetAutocompletePatientsForUser',
      data: data,
      callback: callback,
    });
  }

  SaveMessageByUser(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/AddMessageByUser',
      data: data,
      callback: callback,
    });
  }

  SaveScheduledMessageByUser(data, callback) {
    console.log('SaveScheduledMessageByUser', data);

    // NucleusNetwork.request({
    // 	context : this.context,
    // 	type : "POST",
    // 	url: Config.domain + "CareMessage.asmx/AddScheduledMessageByUser",
    // 	data : data,
    // 	callback : callback,
    // });
    let rsp;
    httpNodeApi
      .post('messages/new', data)
      .then(response => {
        console.log('messages/new response', response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on messages/new' };
        console.log('Error messages/new error', e);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('messages/new finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }

  UploadSignatureMessagePdfFile(data, callback) {
    const body = new FormData();
    body.append('MessageID', data.MessageID);
    body.append('PdfFileName', data.PdfFileName);
    body.append('File', data.File);

    NucleusNetwork.requestWithFile({
      context: this.context,
      type: 'POST',
      url: Config.domain + '/CareMessage.asmx/UploadSignatureMessagePdfFile',
      data: body,
      callback: callback,
    });

    // api({
    //   url: '/CareMessage.asmx/UploadSignatureMessagePdfFile',
    //   method: 'post',
    //   data: body
    // })
    //   .then((response) => {
    // 	callback(response.data)
    //   })
    //   .catch((e) => {
    // 	callback(e.response.data)
    //   })
  }

  DeleteSignatureMessagePdfFile(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/DeleteSignatureMessagePdfFile',
      data: data,
      callback: callback,
    });
  }

  // DEPRECATED: Moved to node
  GetMessageDetails(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetMessageDetails',
      data: data,
      callback: callback,
    });
  }

  GetMessageDetailsForDelete(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetMessageDetailsForDelete',
      data: data,
      callback: callback,
    });
  }

  GetPatientMessageDetails(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetPatientMessageDetails',
      data: data,
      callback: callback,
    });
  }

  GetMessageResponses(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccountReports.asmx/GetDetailMessageReport',
      data: data,
      callback: callback,
    });
  }

  GetMessageReport(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/GetMessageReport',
      data: data,
      callback: callback,
    });
  }

  UpdateMessageByUser(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/UpdateMessage',
      data: data,
      callback: callback,
    });
  }

  UpdateScheduledMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/UpdateScheduledMessage',
      data: data,
      callback: callback,
    });
  }

  StopRecurringMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/StopRecurringMessage',
      data: data,
      callback: callback,
    });
  }

  DeleteMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/DeleteMessage',
      data: data,
      callback: callback,
    });
  }

  DeletePatientMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareMessage.asmx/DeleteScheduledPatientMessage',
      data: data,
      callback: callback,
    });
  }
}

export default MessagesApi;
